/* generate radial color for GS Chart */
export const getRadialColor = (value) => {
  let color = ''
  if (value >= 0 && value <= 25) {
    color = '#f15754'
  } else if (value >= 26 && value <= 49) {
    color = '#ef9434'
  } else if (value >= 50 && value <= 74) {
    color = '#edcd25'
  } else if (value >= 75 && value <= 89) {
    color = '#97d50e'
  } else if (value >= 90 && value <= 100) {
    color = '#3ec60e'
  }
  return color
}

// capitalize first char of string
export const capitalizeFirstChar = (str) => {
  return (str && str.substr(0, 1).toUpperCase() + str.substr(1)) || ''
}

export const getFormattedHeading = (term)=>{
  if(term){
  return term.charAt(0).toUpperCase() + term.slice(1);
  }
  return ""
}