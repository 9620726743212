export const sampleProduct = {
    "productName": "arrowhead mills organic yellow popcorn",
    "upcCode": "74333476658",
    "totalGs": "0",
    "activeGsSystem": "4",
    "processing": 10,
    "nutrition": 10,
    "foodSafety": 10,
    "environment": 10,
}
