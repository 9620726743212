import {
  getOtherProductData,
  getProductDataFromUrl,
} from "../../APIs/functions";
import Chart from "../../Components/Chart";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import "../GSChart/gsChart.scss";
import ChartSkeleton from "./chartSkeleton";
import { sampleProduct } from "./sampleData";

const GSchartNative = () => {
  const queries = {
    retry: true,
    refetchOnmount: true,
    refetchOnWindowFocus: true,
    cacheTime: 325000,
    staleTime: 300000,
  };

  const [searchParams] = useSearchParams();
  let url = searchParams.get("url");

  const [productName, setProductName] = useState("");

  const chartRef = useRef(null);

  const {
    data: product,
    isLoading: productDataLoading,
    isError: productDataError,
    refetch,
    isFetching,
  } = useQuery(
    ["productData", url],
    () => getProductDataFromUrl({ url }),
    queries
  );

  const {
    data: productOtherData,
    isLoading: productOtherDataLoading,
    isError: productOtherDataError,
  } = useQuery(
    ["productOtherData", productName],
    () => getOtherProductData({ productName }),
    {
      ...queries,
      enabled: productName !== "",
    }
  );

  useEffect(() => {
    if (!productDataLoading) {
      setProductName(product?.productNameURL);
    }
  }, [productDataLoading, productDataError, product, url, refetch]);

  useEffect(() => {
    const sendHeight = () => {
      if (chartRef.current) {
        const height =
          chartRef.current.scrollHeight || chartRef.current.offsetHeight;
        window.parent.postMessage(height.toString(), "*");
        console.log(height);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      sendHeight();
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    window.addEventListener("resize", sendHeight);
    sendHeight();

    return () => {
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener("resize", sendHeight);
    };
  }, []);

  return (
    <div ref={chartRef}>
      {product && productOtherData ? (
        <div id={"gsChartExportWithoutHeight"}>
          <Chart
            label={null}
            product={product}
            additionalData={productOtherData}
          />
        </div>
      ) : (
        <ChartSkeleton
          label={null}
          product={sampleProduct}
          additionalData={null}
        />
      )}
    </div>
  );
};

export default GSchartNative;
