import { Spin } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';
import './App.css';

function App() {

  return (
    <>
      <RouterProvider
        router={router}
        fallbackElement={<Spin delay={500} size="small" tip="Researching..." />}
      />
    </>
  );
}

export default App;
