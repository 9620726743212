import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClientProvider , QueryClient } from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styles/index.scss';

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools/>
    </QueryClientProvider >
);

