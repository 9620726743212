import React from "react";
const Food = (props) => {
  return (
    <div className="modal-main">
      <div className="main-heading chart-modal-title">
        <svg
          width="30"
          height="31"
          viewBox="0 0 30 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="14.8895"
            cy="15.2746"
            rx="14.87"
            ry="14.8684"
            fill="#6B9080"
          />
          <path
            d="M14.9708 20.4715C14.9708 20.6139 14.9945 20.7267 15.042 20.8098C15.1014 20.8811 15.1726 20.9167 15.2557 20.9167C15.3863 20.9167 15.5169 20.8811 15.6475 20.8098C15.79 20.7267 15.9622 20.5902 16.164 20.4003L16.3955 20.8632C16.2174 21.1126 15.9444 21.3737 15.5763 21.6468C15.2082 21.9198 14.7749 22.0564 14.2762 22.0564C13.8606 22.0564 13.5401 21.9614 13.3145 21.7714C13.1008 21.5815 13.0117 21.3025 13.0473 20.9345C13.1067 20.4121 13.2611 19.2902 13.5104 17.5689C13.8428 15.2657 14.0447 13.8233 14.1159 13.2416L13.0117 12.8142L13.1364 12.191L15.7722 11.7814L16.1818 11.9773L14.9886 20.2934L14.9708 20.4715ZM15.4694 9.8938C15.1964 9.8938 14.9589 9.79289 14.7571 9.59107C14.5671 9.38925 14.4721 9.14588 14.4721 8.86096C14.4721 8.49293 14.5968 8.17833 14.8461 7.91716C15.1073 7.65598 15.4101 7.52539 15.7544 7.52539C16.075 7.52539 16.3243 7.6263 16.5024 7.82812C16.6805 8.01807 16.7695 8.2555 16.7695 8.54042C16.7577 8.92032 16.633 9.24085 16.3955 9.50203C16.1581 9.76321 15.8494 9.8938 15.4694 9.8938Z"
            fill="white"
          />
        </svg>
        What is food safety?
      </div>
      <div className="info-1">
        {/* <h3 className="info-header">What is food safety?</h3> */}
        <p className="modal-info">
          For the Food Safety Score, each product is evaluated based on (i) the
          risk associated with pesticide and herbicide residues from production
          (considering probability and toxicity), (ii) the use of antibiotics
          and hormones (when raising animals), and (iii) the presence of toxic
          additives (with known health concerns). The lower the risk, use and
          presence, the higher it will score.
        </p>
      </div>
    </div>
  );
};
export default Food;
