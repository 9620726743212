import React, { Component } from "react";
import { Modal, Spin } from "antd";
import lowEnvWater from "../../Assets/Images/chart/lowWater.png";
import veryLowEnvWater from "../../Assets/Images/chart/veryLowWater.png";
import highEnvWater from "../../Assets/Images/chart/highWater.png";
import moderteEnvWater from "../../Assets/Images/chart/moderateWater.png";
import extremeEnvWater from "../../Assets/Images/chart/veryHighWater.png";
import showerImage from "../../Assets/Images/chart/shower.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { getFormattedHeading } from "../../Utils/CommonFunctions";
import "react-perfect-scrollbar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faTimes } from "@fortawesome/free-solid-svg-icons";
import lowEnv from "../../Assets/Images/chart/low.png";
import veryLowEnv from "../../Assets/Images/chart/very_low.png";
import highEnv from "../../Assets/Images/chart/high.png";
import moderteEnv from "../../Assets/Images/chart/moderate.png";
import extremeEnv from "../../Assets/Images/chart/extreme.png";
import carImage from "../../Assets/Images/chart/car.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import Environment from "./Environment";
import Process from "./Process";
import Food from "./Food";
import WhatisDv from "./WhatisDv";
import { handleProcessingGSClicked, handleNutritionGSClicked, handleFoodSafetyGSClicked, handleEnvironmentGSClicked, handleFoodSafetyModal, handleDailyValueModal, handleEnvironmentModal, handleProcessingModal, handleEncourageNutrition, handleLimitNutrition } from '../../Utils/Gtag'
import "./index.scss";

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: this.props?.product,
      currentProductName: this.props?.match?.params?.productName,
      store: "",
      nutritionOp: 1,
      processOp: 1,
      envOp: 1,
      foodOp: 1,
      values: {
        nutrition: [
          { id: 0, name: "Low nutrition", color: "#F15855" },
          { id: 1, name: "Some nutrition value", color: "#F2D95A" },
          { id: 2, name: "High nutrition", color: "#C0E713" },
          { id: 3, name: "Very high nutrition", color: "#43BB1B" },
        ],
        foodSafety: [
          { id: 0, name: "Significant ingredient concerns", color: "#F15855" },

          { id: 1, name: "Significant ingredient concerns", color: "#EF9434" },
          { id: 2, name: "Moderate ingredient concerns", color: "#F2D95A" },

          { id: 3, name: "Minimal ingredient concerns", color: "#43BB1B" },
          { id: 4, name: "No ingredient concerns", color: "#43BB1B" },
        ],
        processing: [
          {
            id: 0,
            name: "Unprocessed or minimally processed",
            color: "#43BB1B",
          },
          { id: 1, name: "Moderately processed", color: "#F2D95A" },
          { id: 2, name: "Highly processed", color: "#CC4A38" },
        ],
        environment: [
          { id: 0, name: "Extremely high footprint", color: "#F15855" },
          { id: 1, name: "Very high footprint", color: "#EF9434" },
          { id: 2, name: "High footprint", color: "#F2D95A" },
          { id: 3, name: "Moderate footprint", color: "#C0E713" },
          { id: 4, name: "Low footprint", color: "#43BB1B" },
          { id: 5, name: "Low footprint", color: "#43BB1B" },
        ],
      },
      pottasium: [
        "Good source of pottasium",
        "Excellent source of pottasium",
        "Bad source of pottasium",
        "Very bad source of pottasium",
      ],
      plantBased: [],
      stickyBottomClass: "",
      stickyTop: "",
      selectedLevel: 0,
      isTopLeft: false,
      isTopRight: false,
      isBottomLeft: false,
      isBottomRight: false,
      swipedNumber: 0,
      nutritionActive: false,
      processingActive: false,
      envActive: false,
      foodSafetyActive: false,
      defaultActive: true,
      isMobile: false,
      showDv: false,
      showFood: false,
      showEnv: false,
      showPr: false,
      saveText: "Save To List",
      removeText: "Remove",
      showProcessModal: false,
      showEnvModal: false,
      showNutritionMdal: false,
      showFoodModal: false,
      productCostShoppable: null,
      allImages: "",
      showBlur: false,
      nutriScore: this.props?.product?.nutrition,
      processScore: this.props?.product?.processing,
      foodSafetyScore: this.props?.product?.foodSafety,
      environmentScore: this.props?.product?.environment,
      gsDescription: this.props?.additionalData?.gsDescription,
      nutritionMasterData: this.props?.additionalData?.nutritionMasterData
    };
    this.product_dec_last_scrolled = React.createRef();
    this.firstRef = React.createRef();
    this.scrolledRef = React.createRef();
    this.nutriDiv = React.createRef();
    this.pdp__gs__chart = React.createRef();
  }

  handleClose = (key) => {
    this.setState({
      showDv: false,
      showPr: false,
      showEnv: false,
      showFood: false,
    });
  };

  capitalizeString = (str) => {
    let capitalize = "";
    let strArray = str.split(" ");
    for (let val of strArray) {
      capitalize += val.substr(0, 1).toUpperCase() + val.substr(1) + " ";
    }
    return capitalize.trim();
  };

  setHoverEffect = (val) => {
    if (val === 1) {
      this.setState({ processOp: 0.5, nutritionOp: 0.5, envOp: 0.5 });
    } else if (val == 2) {
      this.setState({ nutritionOp: 0.5, processOp: 0.5, foodOp: 0.5 });
    } else if (val == 3) {
      this.setState({ nutritionOp: 0.5, envOp: 0.5, foodOp: 0.5 });
    } else if (val == 4) {
      this.setState({ processOp: 0.5, envOp: 0.5, foodOp: 0.5 });
    }
  };

  onMouseLeave = () => {
    this.setState({ nutritionOp: 1, processOp: 1, envOp: 1, foodOp: 1 });
  };

  getTriangleValue = (value) => {
    let valueToDisplay = 0;
    value = parseInt(value);
    let tempValue = 100 - value;
    if (tempValue > 0) {
      if (tempValue >= 80) valueToDisplay = 3.22 * value;
      else if (tempValue >= 70 && tempValue < 80) {
        valueToDisplay = 3.22 * 20 + (value - 20) * 0.9;
      } else if (tempValue >= 60 && tempValue < 70) {
        valueToDisplay = 3.22 * 20 + (value - 20) * 0.8;
      } else if (tempValue >= 0 && tempValue < 60) {
        valueToDisplay = 80.4 + value * 0.15;
      }
    } else valueToDisplay = 100;
    return valueToDisplay;
  };

  getTooltipValue = (value) => {
    let valueToDisplay = 0;
    value = parseInt(value);
    let tempValue = 100 - value;
    if (tempValue > 0) {
      if (tempValue >= 80) valueToDisplay = 3 * value;
      else if (tempValue >= 70 && tempValue < 80) {
        valueToDisplay = 3 * 20 + (value - 20) * 0.9;
      } else if (tempValue >= 60 && tempValue < 70) {
        valueToDisplay = 3 * 20 + (value - 20) * 0.8;
      } else if (tempValue >= 0 && tempValue < 60) {
        valueToDisplay = 76.6 + value * 0.15;
      }
    } else valueToDisplay = 97;
    return valueToDisplay;
  };

  componentWillUnmount() {
    let ele = document.getElementsByClassName("main-wrap");
    if (ele) {
      ele[0].removeEventListener("click", function (e) { });
    }
    document.removeEventListener("scroll", this.stickyElements, false);
  }

  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  calculateFactor = (key, value) => {
    value = parseInt(value);
    if (key.toLowerCase().includes("nutrit")) {
      if (value >= 0 && value < 50) {
        return this.state.values[key][0].name;
      } else if (value >= 50 && value < 60) {
        return this.state.values[key][1].name;
      } else if (value >= 60 && value < 70) {
        return this.state.values[key][2].name;
      } else if (value >= 70 && value <= 100) {
        return this.state.values[key][3].name;
      }
    } else if (key.toLowerCase().includes("foods")) {
      if (value >= 0 && value < 25) {
        // alert(this.state.values[key][4].name)
        return this.state.values[key][0].name;
      } else if (value >= 25 && value < 50) {
        return this.state.values[key][1].name;
      } else if (value >= 50 && value < 75) {
        return this.state.values[key][2].name;
      } else if (value >= 75 && value < 100) {
        return this.state.values[key][3].name;
      } else {
        return this.state.values[key][4].name;
      }
    } else if (key.toLowerCase().includes("envir")) {
      if (value >= 0 && value < 6) {
        return this.state.values[key][0].name;
      } else if (value >= 6 && value < 30) {
        return this.state.values[key][1].name;
      } else if (value >= 30 && value < 65) {
        return this.state.values[key][2].name;
      } else if (value >= 65 && value < 90) {
        return this.state.values[key][3].name;
      } else if (value >= 90 && value < 95) {
        return this.state.values[key][4].name;
      } else {
        return this.state.values[key][5].name;
      }
    } else {
      if (value >= 0 && value < 50) {
        return this.state.values[key][2].name;
      } else if (value >= 50 && value < 90) {
        return this.state.values[key][1].name;
      } else {
        return this.state.values[key][0].name;
      }
    }
  };

  calculateColor = (key, value) => {
    if (key && value) {
      value = parseInt(value);
      if (key.toLowerCase().includes("nutrit")) {
        if (value >= 0 && value < 50) {
          return this.state.values[key][0].color;
        } else if (value >= 50 && value < 60) {
          return this.state.values[key][1].color;
        } else if (value >= 60 && value < 70) {
          return this.state.values[key][2].color;
        } else if (value >= 70 && value <= 100) {
          return this.state.values[key][3].color;
        }
      } else if (key.toLowerCase().includes("foods")) {
        if (value >= 0 && value < 25) {
          return this.state.values[key][0].color;
        } else if (value >= 25 && value < 50) {
          return this.state.values[key][1].color;
        } else if (value >= 50 && value < 75) {
          return this.state.values[key][2].color;
        } else if (value >= 75 && value <= 100) {
          return this.state.values[key][3].color;
        } else {
          return this.state.values[key][4].color;
        }
      } else if (key.toLowerCase().includes("envir")) {
        if (value >= 0 && value < 6) {
          return this.state.values[key][0].color;
        } else if (value >= 6 && value < 30) {
          return this.state.values[key][1].color;
        } else if (value >= 30 && value < 65) {
          return this.state.values[key][2].color;
        } else if (value >= 65 && value < 90) {
          return this.state.values[key][3].color;
        } else {
          return this.state.values[key][4].color;
        }
      } else {
        if (value >= 0 && value < 50) {
          return this.state.values[key][2].color;
        } else if (value >= 50 && value < 90) {
          return this.state.values[key][1].color;
        } else {
          return this.state.values[key][0].color;
        }
      }
    }
  };

  calculateState = (gsSystem, key) => {
    if (this.state.productData !== null) {
    }
  };

  getLimitNutrientColor = (value) => {
    let color = "";
    if (value > 0 && value <= 8) color = "#93D02F";
    else if (value >= 9 && value <= 15) color = "#F3DF23";
    else if (value >= 16 && value <= 25) color = "#EC5F1E";
    else if (value >= 26) color = "#EE1204";
    else {
      color = "#616161";
    }
    return color;
  };

  getEncourageNutrientColor = (value) => {
    let color = "";
    if (value > 0 && value <= 5) color = "#89c62e";
    else if (value > 6 && value <= 10) color = "#85c22e";
    else if (value > 10 && value <= 15) color = "#70ad2c";
    else if (value > 15 && value <= 40) color = "#528f2a";
    else if (value > 40) color = "#e7efe4";
    else {
      color = "#616161";
    }
    return color;
  };

  updateSelected = (e, value) => {
    e.preventDefault();
    if (value === 1) {
      this.setState({
        selectedLevel: this.state?.productData?.foodSafety,
        isTopLeft: true,
        isTopRight: false,
        isBottomLeft: false,
        isBottomRight: false,
      });
    } else if (value === 2) {
      this.setState({
        selectedLevel: this.state?.productData?.environment,
        isTopLeft: false,
        isTopRight: true,
        isBottomLeft: false,
        isBottomRight: false,
      });
    } else if (value === 3) {
      this.setState({
        selectedLevel: this.state?.productData?.processing,
        isTopLeft: false,
        isTopRight: false,
        isBottomLeft: false,
        isBottomRight: true,
      });
    } else if (value === 4) {
      this.setState({
        selectedLevel: this.state?.productData?.nutrition,
        isTopLeft: false,
        isTopRight: false,
        isBottomLeft: true,
        isBottomRight: false,
      });
    }
  };

  setActiveEnv = (env) => {
    if (env.includes("env")) {
      handleEnvironmentGSClicked(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        envActive: true,
        processingActive: false,
        nutritionActive: false,
        foodSafetyActive: false,
        defaultActive: false,
      });
    } else if (env.includes("food")) {
      handleFoodSafetyGSClicked(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        envActive: false,
        processingActive: false,
        nutritionActive: false,
        foodSafetyActive: true,
        defaultActive: false,
      });
    } else if (env.includes("processing")) {
      handleProcessingGSClicked(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        envActive: false,
        processingActive: true,
        nutritionActive: false,
        foodSafetyActive: false,
        defaultActive: false,
      });
    } else if (env.includes("nutri")) {
      handleNutritionGSClicked(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        envActive: false,
        processingActive: false,
        nutritionActive: true,
        foodSafetyActive: false,
        defaultActive: false,
      });
    }
  };

  resetChartState = () => {
    this.setState({
      isTopLeft: false,
      isTopRight: false,
      isBottomLeft: false,
      isBottomRight: false,
      selectedLevel: 0,
      processingActive: false,
      envActive: false,
      nutritionActive: false,
      foodSafetyActive: false,
      defaultActive: true,
    });
  };

  calculateNumColor = (gsScore) => {
    if (this.state?.processingActive === true) {
      var colorEle = document.getElementById("process_color");
      return colorEle ? colorEle.style.backgroundColor : "#65BE41";
    } else if (this.state?.envActive === true) {
      var colorEle = document.getElementById("env_color");
      return colorEle ? colorEle.style.backgroundColor : "#65BE41";
    } else if (this.state?.nutritionActive === true) {
      var colorEle = document.getElementById("nutri_color");
      return colorEle ? colorEle.style.backgroundColor : "#65BE41";
    } else if (this.state?.foodSafetyActive === true) {
      var colorEle = document.getElementById("foodsafety_color");

      return colorEle ? colorEle.style.backgroundColor : "#65BE41";
    } else {
      if (gsScore >= 0 && gsScore <= 25) {
        return "#f15754";
      } else if (gsScore >= 26 && gsScore <= 49) {
        return "#ef9434";
      } else if (gsScore >= 50 && gsScore <= 74) {
        return "#edcd25";
      } else if (gsScore >= 75 && gsScore <= 89) {
        return "#97d50e";
      } else if (gsScore >= 90 && gsScore <= 100) {
        return "#3ec60e";
      }
    }
  };

  openModal = (e, key) => {
    e.stopPropagation();
    e.preventDefault();
    if (key.includes("food")) {
      handleFoodSafetyModal(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        showProcessModal: false,
        showEnvModal: false,
        showNutritionModal: false,
        showFoodModal: true,
      });
    } else if (key.includes("process")) {
      handleProcessingModal(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        showProcessModal: true,
        showEnvModal: false,
        showNutritionModal: false,
        showFoodModal: false,
      });
    } else if (key.includes("nutriti")) {
      handleDailyValueModal(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        showProcessModal: false,
        showEnvModal: false,
        showNutritionModal: true,
        showFoodModal: false,
      });
    } else if (key.includes("envir")) {
      handleEnvironmentModal(this.props.product.productName, this.props.product.upcCode)
      this.setState({
        showProcessModal: false,
        showEnvModal: true,
        showNutritionModal: false,
        showFoodModal: false,
      });
    }
  };

  render() {
    const { productData } = this.state;

    const calculateScale = (value) => {
      if (!value) return 0
      if (value <= 20) {
        return 0.32; // For values 1 to 20
      } else if (value <= 29) {
        return 0.32 + (value - 20) * (0.04 / 9); // Linear interpolation from 0.32 to 0.36 for 21 to 29
      } else if (value <= 35) {
        return 0.36 + (value - 30) * (0.1 / 5); // Linear interpolation from 0.36 to 0.46 for 30 to 35
      }

      // Linear transformation from 0 to 1 for values 36 to 100
      return 0.46 + ((value - 36) * ((1 - 0.46) / (100 - 36)));
    };

    const foodSafetyScale = calculateScale(productData?.foodSafety);
    const processingScale = calculateScale(productData?.processing);
    const nutritionScale = calculateScale(productData?.nutrition);
    const envScale = calculateScale(productData?.environment);

    // nutrition calculation
    let encourage_nutrient = {
      fiber: {},
      calcium: {},
      potassium: {},
      "vitamin D": {},
      protein: {},
      iron: {},
    };
    let limit_nutrient = {
      sodium: {},
      "saturated fats": {},
      cholesterol: {},
    };

    let plant_based = {};
    let added_sugar = {};


    const setEncourageDiscourage = () => {
      // if(productData!==null)
      const nutritionMasterData =
        productData !== null ? this.state?.nutritionMasterData : null;
      const gsDescription =
        productData !== null ? this.state?.gsDescription : null;
      if (gsDescription !== null && gsDescription?.length > 0)
        for (let gd of gsDescription) {
          // to encourage nutrient
          if (gd.subSystem === "dietary fiber")
            encourage_nutrient.fiber = { ...gd };
          if (gd.subSystem === "calcium")
            encourage_nutrient.calcium = { ...gd };
          if (gd.subSystem === "Potassium")
            encourage_nutrient.potassium = { ...gd };
          if (gd.subSystem === "vitamin D")
            encourage_nutrient["vitamin D"] = { ...gd };
          if (gd.subSystem === "protein")
            encourage_nutrient.protein = { ...gd };
          if (gd.subSystem === "iron") encourage_nutrient.iron = { ...gd };
          if (gd.subSystem === "fruitVeggie") {
            let { description, infoAlert } = gd;
            let infoObj = infoAlert && Object.keys(infoAlert).length > 0 && infoAlert
            let infoText = infoObj && Object.keys(infoObj).length > 0 && infoObj?.info && infoObj?.info[0];
            plant_based = { infoText, description };
          }
          // to limit nutrient
          if (gd.subSystem === "sodium") limit_nutrient.sodium = { ...gd };
          if (gd.subSystem === "saturated fat")
            limit_nutrient["saturated fats"] = { ...gd };
          if (gd.subSystem === "cholesterol")
            limit_nutrient.cholesterol = { ...gd };
          if (gd.subSystem === "addedSugar") {
            let { description, infoAlert } = gd;
            let infoObj = infoAlert && Object.keys(infoAlert).length > 0 && infoAlert;
            let infoText = infoObj && Object.keys(infoObj).length > 0 && infoObj?.info && infoObj?.info[0];
            added_sugar = { infoText, description };
          }
        }

      // to encourage nutrient
      encourage_nutrient.fiber = {
        ...encourage_nutrient.fiber,
        value: nutritionMasterData && nutritionMasterData.Dietary_Fiber_DV,
      };
      encourage_nutrient.calcium = {
        ...encourage_nutrient.calcium,
        value: nutritionMasterData && nutritionMasterData.Calcium_DV,
      };
      encourage_nutrient.potassium = {
        ...encourage_nutrient.potassium,
        value: nutritionMasterData && nutritionMasterData.Potassium_DV,
      };
      encourage_nutrient["vitamin D"] = {
        ...encourage_nutrient["vitamin D"],
        value: nutritionMasterData && nutritionMasterData.Vitamin_D_DV,
      };
      encourage_nutrient.protein = {
        ...encourage_nutrient.protein,
        value: nutritionMasterData && nutritionMasterData.Protein_DV,
      };
      encourage_nutrient.iron = {
        ...encourage_nutrient.iron,
        value: nutritionMasterData && nutritionMasterData.Iron_DV,
      };

      // to limit nutrient
      limit_nutrient.cholesterol = {
        ...limit_nutrient.cholesterol,
        value: nutritionMasterData && nutritionMasterData.Cholesterol_DV,
      };
      limit_nutrient["saturated fats"] = {
        ...limit_nutrient["saturated fats"],
        value: nutritionMasterData && nutritionMasterData.Saturated_Fat_DV,
      };
      limit_nutrient.sodium = {
        ...limit_nutrient.sodium,
        value: nutritionMasterData && nutritionMasterData.Sodium_DV,
      };
    };
    setEncourageDiscourage();
    // nutrition calculation ends

    const { nutrition, foodSafety, processing, environment } = this.props.product;
    const valuesGreaterThanZero = [nutrition, foodSafety, processing, environment].filter(val => val > 0);

    let pieActiveClass = "";

    switch (valuesGreaterThanZero.length) {
      case 0:
        pieActiveClass = "three-pie";
        break;
      case 1:
        pieActiveClass = "one-pie";
        break;
      case 2:
        pieActiveClass = "two-pie";
        break;
      case 3:
        pieActiveClass = "three-pie";
        break;
      case 4:
        pieActiveClass = "";
        break;
      default:
        pieActiveClass = "";
        break;
    }

    return (
      <div className="container">
        {/* <h3 className="chartTitle" ref={this.props.customRef}>
          {" "}
          The GreenScore® rating{" "}
        </h3> */}
        <div id="gs-chart-for-hp" className="main-wrap prdct-detail-main">
          <React.Fragment>
            <Modal
              open={this.state.showEnvModal}
              className="chart-description-modal"
              onCancel={() =>
                this.setState({
                  showEnvModal: false,
                  showProcessModal: false,
                  showNutritionModal: false,
                  showFoodModal: false,
                })
              }
              centered
              footer={null}
            >
              <Environment />
            </Modal>
            <Modal
              open={this.state.showNutritionModal}
              className="chart-description-modal"
              onCancel={() =>
                this.setState({
                  showEnvModal: false,
                  showProcessModal: false,
                  showNutritionModal: false,
                  showFoodModal: false,
                })
              }
              centered
              footer={null}
            >
              <WhatisDv />
            </Modal>
            <Modal
              open={this.state.showFoodModal}
              className="chart-description-modal"
              onCancel={() =>
                this.setState({
                  showEnvModal: false,
                  showProcessModal: false,
                  showNutritionModal: false,
                  showFoodModal: false,
                })
              }
              centered
              footer={null}
            >
              <Food />
            </Modal>
            <Modal
              open={this.state.showProcessModal}
              className="chart-description-modal"
              onCancel={() =>
                this.setState({
                  showEnvModal: false,
                  showProcessModal: false,
                  showNutritionModal: false,
                  showFoodModal: false,
                })
              }
              centered
              footer={null}
            >
              <Process />
            </Modal>
          </React.Fragment>

          {!this.state.loading ? (
            this.state?.productData !== "" &&
              this.state?.productData !== "undefined" &&
              this.state?.productData !== null ? (
              <div className="gs-chart-lp-wrap">
                <section
                  className="product_dec_last_scrolled_holder w-100"
                  id="pdp__gs__chart"
                  ref={this.pdp__gs__chart}
                >
                  <div
                    className="product_dec_last_scrolled"
                    ref={this.product_dec_last_scrolled}
                  >
                    {this.props.label && <div className="First Text d-block d-lg-none">
                      <h4 className="first_content px-1">
                        Tap the chart to learn why it got a GreenScore® rating
                        of {Math.round(productData?.totalGs)}
                        /100!
                      </h4>
                    </div>}
                    <div
                      onClick={() => this.resetChartState()}
                      className="product_dec_last_scrolled_left"
                      id="product_dec_last_scrolled_left"
                    >
                      <div
                        className={`chart__svg__holder ${pieActiveClass}`}
                        ref={(holder) => (this.scrolledRef = holder)}
                        id="chart_holder_main"
                        onClick={(e) => {
                          e.preventDefault();
                          this.resetChartState();
                        }}
                      >

                        <div className="d-none scoreTitle">
                          {this.state.nutriScore && this.state.nutriScore > 0 && <img alt="nutrition" src="./images/one-pie/nutrition.svg" />}
                          {this.state.processScore && this.state.processScore > 0 && <img alt="processing" src="./images/one-pie/processing.svg" />}
                          {this.state.environmentScore && this.state.environmentScore > 0 && <img alt="environment" src="./images/one-pie/environment.svg" />}
                          {this.state.foodSafetyScore && this.state.foodSafetyScore > 0 && <img alt="foodsafety" src="./images/one-pie/foodsafety.svg" />}
                        </div>

                        {this.state.nutriScore && this.state.nutriScore > 0 ? <div
                          className={
                            this.state.isBottomLeft === true
                              ? "quarteree-circle-top-left circle-selected"
                              : "quarteree-circle-top-left"
                          }
                        >
                          <div className="nutrition__image">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 174.75 167.97"
                            >
                              <defs></defs>
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <text
                                    className="cls-1"
                                    transform="translate(19.06 99.63) rotate(-62.66)"
                                  >
                                    N
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.55, -0.84, 0.84, 0.55, 27.78, 83.06)"
                                  >
                                    u
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(36.33 70.21) rotate(-52.4)"
                                  >
                                    t
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(42.87 61.76) rotate(-48.69)"
                                  >
                                    r
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(50.26 53.46) rotate(-45.53)"
                                  >
                                    i
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(55.74 47.87) rotate(-42.4)"
                                  >
                                    t
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(63.7 40.68) rotate(-39.29)"
                                  >
                                    i
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(69.63 35.72) rotate(-35.43)"
                                  >
                                    o
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(82.01 26.95) rotate(-30.3)"
                                  >
                                    n
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span className="sv__icon" data-text="Nutrition">
                            <svg
                              width="22"
                              height="26"
                              viewBox="0 0 22 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.57467 8.8761C9.57467 8.8761 9.13052 2.0009 17.2083 0.966675C17.2083 0.966675 18.1156 7.78254 9.57467 8.8761Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M9.4078 8.67003C9.19754 8.66996 8.99568 8.58751 8.84548 8.44037C8.69527 8.29324 8.60868 8.09313 8.60427 7.88291C8.60335 7.84013 8.94647 6.40454 6.7817 5.21008C6.5966 5.10624 6.46008 4.9334 6.4019 4.72929C6.34371 4.52518 6.3686 4.30635 6.47112 4.12051C6.57364 3.93468 6.74548 3.79694 6.94916 3.7373C7.15285 3.67766 7.37185 3.70095 7.55841 3.80213C10.5372 5.44562 10.2079 7.64578 10.212 7.85005C10.2162 8.06324 10.1355 8.26937 9.98778 8.4231C9.84002 8.57683 9.63724 8.66553 9.42405 8.66975L9.4078 8.67003Z"
                                fill="#6B9080"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.6829 11.0719C4.28874 10.3681 5.3366 9.52925 6.88804 9.52925C7.32276 9.52925 7.75982 9.60143 8.18706 9.74382C9.01855 10.021 9.87842 10.1615 10.7428 10.1615C11.6071 10.1615 12.467 10.021 13.2985 9.74382C13.7257 9.60143 14.1627 9.52925 14.5975 9.52925C16.1489 9.52925 17.1968 10.3681 17.8026 11.0719C18.1646 11.4924 18.4708 11.9742 18.7174 12.5019C19.5544 13.8918 19.8734 15.6644 19.5761 17.46C19.3381 18.8979 18.6995 20.1998 17.8194 21.2109C16.7509 22.6805 15.2706 23.6126 13.7358 23.6126C13.4826 23.6126 13.2304 23.5879 12.9863 23.5394C12.2508 23.3931 11.496 23.3189 10.7428 23.3189C9.98949 23.3189 9.23469 23.3931 8.49931 23.5393C8.25507 23.5879 8.0029 23.6126 7.7497 23.6126C6.20451 23.6126 4.71457 22.6678 3.64444 21.181C2.77773 20.1742 2.14928 18.8837 1.91361 17.46C1.61864 15.678 1.93051 13.9186 2.75345 12.5335C3.00247 11.9935 3.31362 11.5008 3.6829 11.0719ZM8.73178 8.10935C8.13825 7.91155 7.52004 7.8064 6.88804 7.8064C2.50671 7.8064 -0.331346 12.5542 0.43826 17.6738C1.10781 22.1281 4.34775 25.3354 7.7497 25.3354C8.12137 25.3354 8.48397 25.299 8.83544 25.2291C9.46334 25.1042 10.103 25.0417 10.7428 25.0417C11.3825 25.0417 12.0222 25.1042 12.6501 25.2291C13.0015 25.299 13.3641 25.3354 13.7358 25.3354C17.1378 25.3354 20.3777 22.1281 21.0472 17.6738C21.8168 12.5542 18.9788 7.8064 14.5975 7.8064C13.9655 7.8064 13.3472 7.91155 12.7537 8.10935C12.0951 8.32886 11.4189 8.43867 10.7428 8.43867C10.0666 8.43867 9.39042 8.32886 8.73178 8.10935Z"
                                fill="#6B9080"
                              />
                            </svg>
                          </span>
                        </div> : null}

                        {this.state.processScore && this.state.processScore > 0 ? <div
                          className={
                            this.state.isBottomRight === true
                              ? "quarteree-circle-top-right circle-selected"
                              : "quarteree-circle-top-right"
                          }
                        >
                          <div className="processing__image">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 173.4 174.15"
                            >
                              {" "}
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <text
                                    className="cls-1"
                                    transform="translate(66.59 19.34) rotate(25.46)"
                                  >
                                    P
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.87, 0.5, -0.5, 0.87, 80.63, 26.13)"
                                  >
                                    r
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.83, 0.55, -0.55, 0.83, 89.1, 30.87)"
                                  >
                                    o
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.79, 0.62, -0.62, 0.79, 100.68, 38.59)"
                                  >
                                    c
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(110.84 46.56) rotate(42.62)"
                                  >
                                    e
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.68, 0.73, -0.73, 0.68, 120.75, 55.7)"
                                  >
                                    s
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(128.84 64.32) rotate(50.86)"
                                  >
                                    s
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.59, 0.81, -0.81, 0.59, 136.31, 73.6)"
                                  >
                                    i
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.54, 0.84, -0.84, 0.54, 140.2, 78.75)"
                                  >
                                    n
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(147.79 90.65) rotate(62.3)"
                                  >
                                    g
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span className="sv__icon" data-text="Nutrition">
                            <svg
                              width="25"
                              height="28"
                              viewBox="0 0 25 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1992 2.63031L22.9764 5.40745L7.34268 21.0411C6.97429 21.4095 6.47465 21.6165 5.95367 21.6165C5.43268 21.6165 4.93304 21.4095 4.56465 21.0411C4.19665 20.6727 3.99002 20.1731 3.99019 19.6524C3.99036 19.1316 4.1973 18.6322 4.56554 18.264L20.1992 2.63031Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M23.0451 2.55741C23.513 3.02523 23.7758 3.65972 23.7758 4.32131C23.7758 4.9829 23.513 5.61739 23.0451 6.0852L17.4219 11.7084L13.8941 8.18066L19.5174 2.55741C19.9852 2.0896 20.6197 1.82678 21.2812 1.82678C21.9428 1.82678 22.5773 2.0896 23.0451 2.55741V2.55741Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M4.74958 25.5619C4.74958 24.2415 2.63635 20.6304 2.63635 20.6304C2.63635 20.6304 0.523264 24.2415 0.523264 25.5619C0.488992 26.1585 0.691769 26.7446 1.08756 27.1924C1.48335 27.6401 2.04001 27.9134 2.63635 27.9527C3.23268 27.9134 3.78935 27.6401 4.18514 27.1924C4.58093 26.7446 4.78385 26.1585 4.74958 25.5619Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M8.37109 15.0654L13.8577 9.57867L16.0247 11.7456L12.0234 15.7468L8.37109 15.0654Z"
                                fill="white"
                                className=""
                              />
                              <path
                                d="M13.8563 10.1863L15.413 11.743L11.8783 15.2778L9.25235 14.7889L13.8551 10.1863H13.8563ZM13.8551 8.96588L7.48438 15.3366L12.1671 16.2119L16.6323 11.7467L13.8551 8.96962V8.96588Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M18.2382 12.5171L13.0739 7.35274C12.7159 6.99481 12.1356 6.99481 11.7777 7.35274C11.4198 7.71067 11.4198 8.29099 11.7777 8.64891L16.9421 13.8133C17.3 14.1712 17.8803 14.1712 18.2382 13.8133C18.5962 13.4554 18.5962 12.875 18.2382 12.5171Z"
                                fill="#6B9080"
                              />
                            </svg>
                          </span>
                        </div> : null}

                        {this.state.environmentScore && this.state.environmentScore > 0 ? <div
                          className={
                            this.state.isTopRight === true
                              ? "quarteree-circle-bottom-left circle-selected"
                              : "quarteree-circle-bottom-left"
                          }
                        >
                          <div className="environment__image">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 202.05 184.35"
                            >
                              {" "}
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <text
                                    className="cls-1"
                                    transform="translate(9.52 47.72) rotate(69.75)"
                                  >
                                    E
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(15.9 64.58) rotate(64.35)"
                                  >
                                    n
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.51, 0.86, -0.86, 0.51, 23.29, 79.69)"
                                  >
                                    v
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(31.5 93.18) rotate(55.58)"
                                  >
                                    i
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.61, 0.79, -0.79, 0.61, 36.54, 100.51)"
                                  >
                                    r
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(44.15 110.36) rotate(47.99)"
                                  >
                                    o
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.73, 0.68, -0.68, 0.73, 55.52, 122.81)"
                                  >
                                    n
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(68.24 134.68) rotate(36.63)"
                                  >
                                    m
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.86, 0.51, -0.51, 0.86, 88.11, 149)"
                                  >
                                    e
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.9, 0.43, -0.43, 0.9, 102.36, 157.27)"
                                  >
                                    n
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(117.68 164.36) rotate(21.13)"
                                  >
                                    t
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span className="sv__icon" data-text="Nutrition">
                            <svg
                              width="27"
                              height="21"
                              viewBox="0 0 27 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5075 14.4339C16.1889 14.4339 18.3626 12.2602 18.3626 9.57877C18.3626 6.89735 16.1889 4.72363 13.5075 4.72363C10.8261 4.72363 8.65234 6.89735 8.65234 9.57877C8.65234 12.2602 10.8261 14.4339 13.5075 14.4339Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M10.0938 19.9986L17.4282 1.60919L24.8384 19.9986H10.0938Z"
                                fill="white"
                                className="fill__none"
                              />
                              <path
                                d="M17.4273 4.01381L23.5075 19.1002H11.4115L17.4273 4.01381ZM17.4273 0.578713C17.2634 0.577111 17.1031 0.625576 16.9676 0.717599C16.832 0.809622 16.7277 0.940802 16.6688 1.09366L8.77344 20.8923H26.1645L18.1845 1.09122C18.1251 0.939236 18.021 0.808997 17.8858 0.717673C17.7505 0.626348 17.5904 0.57826 17.4273 0.579896V0.578713Z"
                                fill="#6B9080"
                              />
                              <path
                                d="M2.28125 19.9859L8.82412 6.51093L16.0699 19.9859H2.28125Z"
                                fill="white"
                                className="fill__none"
                              />
                              <path
                                d="M8.85932 8.48818L14.5662 19.0999H3.71093L8.85932 8.48818ZM8.81554 5.53053C8.66188 5.52955 8.51093 5.57232 8.38069 5.65385C8.25045 5.73539 8.14638 5.85229 8.08014 5.99094L0.851562 20.8944H17.5664L9.53792 5.96428C9.46877 5.83361 9.36487 5.72444 9.23796 5.6486C9.11106 5.57276 8.96574 5.53317 8.8179 5.53416L8.81554 5.53053Z"
                                fill="#6B9080"
                              />
                            </svg>
                          </span>
                        </div> : null}

                        {this.state.foodSafetyScore && this.state.foodSafetyScore > 0 ? <div
                          className={
                            this.state.isTopLeft === true
                              ? "quarteree-circle-bottom-right circle-selected"
                              : "quarteree-circle-bottom-right"
                          }
                        >
                          <div className="food___image">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 177.77 175.13"
                            >
                              {" "}
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <text
                                    className="cls-1"
                                    transform="translate(61.19 166.25) rotate(-22.04)"
                                  >
                                    F
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(75.22 160.52) rotate(-26.89)"
                                  >
                                    o
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(89.65 153.1) rotate(-32.08)"
                                  >
                                    o
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(103.39 144.38) rotate(-37.28)"
                                  >
                                    d
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.75, -0.66, 0.66, 0.75, 116.03, 134.51)"
                                  >
                                    {" "}
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.71, -0.71, 0.71, 0.71, 122.13, 129.32)"
                                  >
                                    S
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(134.22 116.99) rotate(-50.41)"
                                  >
                                    a
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="matrix(0.58, -0.82, 0.82, 0.58, 143.99, 104.92)"
                                  >
                                    f
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(150.07 96.41) rotate(-58.85)"
                                  >
                                    e
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(158 83.01) rotate(-63.24)"
                                  >
                                    t
                                  </text>
                                  <text
                                    className="cls-1"
                                    transform="translate(163.16 72.8) rotate(-67.68)"
                                  >
                                    y
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span className="sv__icon" data-text="Nutrition">
                            <svg
                              width="23"
                              height="25"
                              viewBox="0 0 23 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.8357 2.27101C14.639 2.27415 17.4167 2.80194 20.0258 3.82715L21.0326 4.21965V7.05964C21.0349 8.85216 20.7903 10.6364 20.3052 12.362C19.8503 13.9916 19.1666 15.5485 18.2743 16.9859C17.4351 18.3428 16.3901 19.5611 15.1768 20.5971C14.1739 21.4518 13.0479 22.1504 11.8368 22.6694C10.6257 22.1504 9.49917 21.4518 8.49627 20.5971C7.28281 19.5612 6.23836 18.3429 5.39934 16.9859C4.50684 15.5484 3.82222 13.9916 3.36676 12.362C2.88224 10.6363 2.63801 8.85208 2.64043 7.05964V4.21965L3.64723 3.82715C6.25636 2.80192 9.03458 2.27412 11.8379 2.27101H11.8357ZM11.8379 0.578674C8.82443 0.582543 5.83785 1.14952 3.03265 2.2504L0.949219 3.06284V7.05852C0.949219 15.0522 5.36153 22.1155 11.8379 24.4875C18.3143 22.1155 22.7261 15.0522 22.7261 7.05852V3.06284L20.6426 2.2504C17.8374 1.14952 14.8514 0.582543 11.8379 0.578674Z"
                                fill="#6B9080"
                              />
                            </svg>
                          </span>
                        </div> : null}

                        <div className="chart__svg">
                          <div className="chart__number">
                            <span
                              style={{
                                color: `${this.calculateNumColor(
                                  productData
                                    ? Math.round(productData?.totalGs)
                                    : ""
                                )}`,
                              }}
                            >
                              {productData && this.state.selectedLevel === 0
                                ? Math.round(productData?.totalGs)
                                : this.state.selectedLevel}
                            </span>
                          </div>

                          {productData && this.state.nutriScore && this.state.nutriScore > 0 ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.setState({ showBlur: true });
                                var ele = document.getElementById(`chart_1`);
                                this.updateSelected(e, 4);
                                this.setActiveEnv("nutrition");
                              }}
                              className="quarter-circle-top-left"
                            >
                              <span className="sv__icon" data-text="Nutrition">
                                <svg
                                  width="22"
                                  height="26"
                                  viewBox="0 0 22 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.57467 8.8761C9.57467 8.8761 9.13052 2.0009 17.2083 0.966675C17.2083 0.966675 18.1156 7.78254 9.57467 8.8761Z"
                                    fill="#234a5d"
                                  ></path>
                                  <path
                                    d="M9.4078 8.67003C9.19754 8.66996 8.99568 8.58751 8.84548 8.44037C8.69527 8.29324 8.60868 8.09313 8.60427 7.88291C8.60335 7.84013 8.94647 6.40454 6.7817 5.21008C6.5966 5.10624 6.46008 4.9334 6.4019 4.72929C6.34371 4.52518 6.3686 4.30635 6.47112 4.12051C6.57364 3.93468 6.74548 3.79694 6.94916 3.7373C7.15285 3.67766 7.37185 3.70095 7.55841 3.80213C10.5372 5.44562 10.2079 7.64578 10.212 7.85005C10.2162 8.06324 10.1355 8.26937 9.98778 8.4231C9.84002 8.57683 9.63724 8.66553 9.42405 8.66975L9.4078 8.67003Z"
                                    fill="#234a5d"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.6829 11.0719C4.28874 10.3681 5.3366 9.52925 6.88804 9.52925C7.32276 9.52925 7.75982 9.60143 8.18706 9.74382C9.01855 10.021 9.87842 10.1615 10.7428 10.1615C11.6071 10.1615 12.467 10.021 13.2985 9.74382C13.7257 9.60143 14.1627 9.52925 14.5975 9.52925C16.1489 9.52925 17.1968 10.3681 17.8026 11.0719C18.1646 11.4924 18.4708 11.9742 18.7174 12.5019C19.5544 13.8918 19.8734 15.6644 19.5761 17.46C19.3381 18.8979 18.6995 20.1998 17.8194 21.2109C16.7509 22.6805 15.2706 23.6126 13.7358 23.6126C13.4826 23.6126 13.2304 23.5879 12.9863 23.5394C12.2508 23.3931 11.496 23.3189 10.7428 23.3189C9.98949 23.3189 9.23469 23.3931 8.49931 23.5393C8.25507 23.5879 8.0029 23.6126 7.7497 23.6126C6.20451 23.6126 4.71457 22.6678 3.64444 21.181C2.77773 20.1742 2.14928 18.8837 1.91361 17.46C1.61864 15.678 1.93051 13.9186 2.75345 12.5335C3.00247 11.9935 3.31362 11.5008 3.6829 11.0719ZM8.73178 8.10935C8.13825 7.91155 7.52004 7.8064 6.88804 7.8064C2.50671 7.8064 -0.331346 12.5542 0.43826 17.6738C1.10781 22.1281 4.34775 25.3354 7.7497 25.3354C8.12137 25.3354 8.48397 25.299 8.83544 25.2291C9.46334 25.1042 10.103 25.0417 10.7428 25.0417C11.3825 25.0417 12.0222 25.1042 12.6501 25.2291C13.0015 25.299 13.3641 25.3354 13.7358 25.3354C17.1378 25.3354 20.3777 22.1281 21.0472 17.6738C21.8168 12.5542 18.9788 7.8064 14.5975 7.8064C13.9655 7.8064 13.3472 7.91155 12.7537 8.10935C12.0951 8.32886 11.4189 8.43867 10.7428 8.43867C10.0666 8.43867 9.39042 8.32886 8.73178 8.10935Z"
                                    fill="#234a5d"
                                  ></path>
                                </svg>
                              </span>
                              <div
                                id="nutri_color"
                                onMouseLeave={() => this.onMouseLeave()}
                                onMouseEnter={() => this.setHoverEffect(4)}
                                className="quarter_inner"
                                style={{
                                  backgroundColor: this.calculateColor(
                                    "nutrition",
                                    productData.nutrition
                                  ),
                                  opacity: this.state.nutritionOp,
                                  transform: `scale(${nutritionScale})`,
                                  transformOrigin: "bottom right",
                                }}
                              ></div>
                            </div>
                          ) : null}

                          {productData && this.state.processScore && this.state.processScore > 0 ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.setState({ showBlur: true });
                                this.updateSelected(e, 3);
                                this.setActiveEnv("processing");
                              }}
                              className="quarter-circle-top-right"
                            >
                              <span className="sv__icon" data-text="Processing">
                                <svg
                                  width="21"
                                  height="25"
                                  viewBox="0 0 21 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20.7697 2.53864C20.7697 1.95482 20.5377 1.39491 20.1249 0.982086C19.7121 0.569263 19.1522 0.337341 18.5684 0.337341C17.9845 0.337341 17.4246 0.569263 17.0118 0.982086L12.0566 5.93725L11.3278 5.20838C11.0119 4.89252 10.4998 4.89252 10.184 5.20838C9.8681 5.52423 9.8681 6.03633 10.184 6.35219L11.2491 7.41737L3.82782 14.8387C3.50287 15.1636 3.32025 15.6043 3.32011 16.0639C3.31996 16.5234 3.5023 16.9642 3.82704 17.2894C4.15213 17.6145 4.59304 17.7971 5.05278 17.7971C5.51252 17.7971 5.95343 17.6145 6.27851 17.2894L13.6998 9.86806L14.7413 10.9095C15.0571 11.2253 15.5692 11.2253 15.8851 10.9095C16.2009 10.5936 16.2009 10.0815 15.8851 9.76568L15.1697 9.05035L20.1249 4.09519C20.5377 3.68236 20.7697 3.12246 20.7697 2.53864ZM13.0337 9.4309L11.66 8.05716H11.6589L7.59726 12.1188L9.91452 12.5502L13.0337 9.4309ZM2.55353 18.1326C2.55353 18.1326 4.41836 21.3192 4.41836 22.4844C4.4486 23.0109 4.26953 23.5281 3.92026 23.9232C3.571 24.3183 3.07977 24.5595 2.55353 24.5941C2.0273 24.5595 1.53606 24.3183 1.1868 23.9232C0.837538 23.5281 0.658598 23.0109 0.688841 22.4844C0.688841 21.3192 2.55353 18.1326 2.55353 18.1326Z"
                                    fill="#234a5d"
                                  />
                                </svg>
                              </span>
                              <div
                                id="process_color"
                                onMouseLeave={() => this.onMouseLeave()}
                                onMouseEnter={() => this.setHoverEffect(3)}
                                className="quarter_inner"
                                style={{
                                  backgroundColor: this.calculateColor(
                                    "processing",
                                    productData.processing
                                  ),
                                  opacity: this.state.processOp,
                                  transform: `scale(${processingScale})`,
                                  transformOrigin: "bottom left",
                                }}
                              ></div>
                            </div>
                          ) : null}

                          {productData && this.state.environmentScore && this.state.environmentScore > 0 ? <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.setState({ showBlur: true });
                              this.updateSelected(e, 2);
                              this.setActiveEnv("environment");
                            }}
                            className="quarter-circle-bottom-left"
                          >
                            <span className="sv__icon" data-text="Environment">
                              <svg
                                width="31"
                                height="24"
                                viewBox="0 0 31 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.8273 5.04122C14.0997 5.04122 15.1311 4.00976 15.1311 2.73738C15.1311 1.465 14.0997 0.433533 12.8273 0.433533C11.5549 0.433533 10.5234 1.465 10.5234 2.73738C10.5234 4.00976 11.5549 5.04122 12.8273 5.04122Z"
                                  fill="#234a5d"
                                />
                                <path
                                  d="M19.2049 6.7123L26.7633 21.7987H11.7267L19.2049 6.7123ZM19.2049 3.2772C19.0013 3.2756 18.802 3.32406 18.6335 3.41608C18.465 3.50811 18.3354 3.63929 18.2621 3.79214L8.44727 23.5908H28.4469C29.1906 23.5908 29.6741 22.8078 29.341 22.1429L20.1463 3.7897C20.0724 3.63772 19.9431 3.50748 19.7749 3.41616C19.6068 3.32483 19.4078 3.27675 19.2049 3.27838V3.2772Z"
                                  fill="#234a5d"
                                />
                                <path
                                  d="M10.2495 11.188L12.7313 14.8811L9.28087 21.7997H3.84944L10.2495 11.188ZM10.1951 8.2303C10.004 8.22932 9.81639 8.27209 9.65448 8.35362C9.49258 8.43516 9.36321 8.55206 9.28087 8.6907L1.20919 22.0779C0.807341 22.7443 1.28731 23.5942 2.06557 23.5942H6.27326H8.54289H10.2495L13.9453 12.6696L11.0931 8.66404C11.0071 8.53337 10.8779 8.42421 10.7202 8.34837C10.5624 8.27253 10.3818 8.23294 10.198 8.23393L10.1951 8.2303Z"
                                  fill="#234a5d"
                                />
                              </svg>
                            </span>

                            <div
                              id="env_color"
                              onMouseLeave={() => this.onMouseLeave()}
                              onMouseEnter={() => this.setHoverEffect(2)}
                              className="quarter_inner"
                              style={{
                                backgroundColor:
                                  productData !== null
                                    ? this.calculateColor(
                                      "environment",
                                      productData?.environment
                                    )
                                    : "",
                                opacity: this.state.envOp,
                                transform: `scale(${envScale})`,
                                transformOrigin: "top right",
                              }}
                            ></div>
                          </div> : null}

                          {productData && this.state.foodSafetyScore && this.state.foodSafetyScore > 0 ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.setState({ showBlur: true });
                                this.updateSelected(e, 1);
                                this.setActiveEnv("foodSafety");
                              }}
                              className="quarter-circle-bottom-right"
                            >
                              <span className="sv__icon" data-text="FoodSafety">
                                <svg
                                  width="22"
                                  height="25"
                                  viewBox="0 0 22 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.7826 2.49442C13.4978 2.49747 16.1881 3.00867 18.7153 4.00166L19.6904 4.38181V7.13254C19.6927 8.86871 19.4558 10.5969 18.9858 12.2682C18.5453 13.8466 17.8831 15.3545 17.0188 16.7468C16.2059 18.0611 15.1938 19.241 14.0186 20.2445C13.0473 21.0723 11.9567 21.7489 10.7836 22.2516C9.61057 21.7489 8.51949 21.0723 7.54812 20.2445C6.37279 19.2412 5.36117 18.0612 4.54853 16.7468C3.68408 15.3545 3.02098 13.8466 2.57983 12.2682C2.11054 10.5968 1.87399 8.86864 1.87634 7.13254V4.38181L2.85149 4.00166C5.37861 3.00865 8.0695 2.49744 10.7847 2.49442H10.7826ZM10.7847 0.855286C7.86596 0.859033 4.97325 1.40819 2.25622 2.47447L0.238281 3.26137V7.13146C0.238281 14.8738 4.5119 21.7152 10.7847 24.0127C17.0575 21.7152 21.3306 14.8738 21.3306 7.13146V3.26137L19.3127 2.47447C16.5957 1.40819 13.7035 0.859033 10.7847 0.855286Z"
                                    fill="#234a5d"
                                  />
                                  <path
                                    d="M10.8025 7.91766H9.74836C9.63543 7.92418 9.52521 7.95439 9.42476 8.00641C9.32431 8.05843 9.23604 8.13108 9.16555 8.21956C9.09506 8.30803 9.04391 8.41036 9.01565 8.51989C8.98739 8.62943 8.98269 8.74374 9.00158 8.85527L10.0568 17.42C10.0712 17.6105 10.1549 17.789 10.2928 17.9213C10.4306 18.0537 10.6126 18.1305 10.8036 18.1371C10.9946 18.1305 11.1766 18.0537 11.3144 17.9213C11.4523 17.789 11.536 17.6105 11.5504 17.42L12.6056 8.85527C12.6245 8.74374 12.6198 8.62943 12.5916 8.51989C12.5633 8.41036 12.5121 8.30803 12.4417 8.21956C12.3712 8.13108 12.2829 8.05843 12.1825 8.00641C12.082 7.95439 11.9718 7.92418 11.8588 7.91766H10.8025Z"
                                    fill="#234a5d"
                                  />
                                  <path
                                    d="M10.7942 7.04532H9.47547C9.43236 7.04585 9.3901 7.03605 9.35154 7.01677C9.31299 6.9975 9.27957 6.96926 9.25414 6.93445L8.78388 6.26952C8.73108 6.19402 8.69665 6.10739 8.68268 6.01633C8.66872 5.92526 8.67604 5.83218 8.70379 5.74433C8.73154 5.65648 8.77858 5.5762 8.84232 5.50968C8.90606 5.44316 8.98464 5.3922 9.07123 5.36072C9.16504 5.32778 9.26595 5.32073 9.36345 5.34023C9.46094 5.35973 9.55144 5.4051 9.62536 5.47159L9.80124 5.62893C9.82937 5.65397 9.86278 5.67288 9.89864 5.6844C9.9345 5.69592 9.97212 5.6998 10.0096 5.69583C10.047 5.69187 10.0835 5.68011 10.1162 5.66133C10.1488 5.64256 10.1773 5.61717 10.1995 5.58679C10.3019 5.44772 10.4352 5.33453 10.5891 5.25622C10.7431 5.1779 10.9132 5.13664 11.0859 5.13574H11.3343C11.3711 5.13583 11.4072 5.14345 11.4409 5.15806C11.4746 5.17268 11.505 5.19401 11.5302 5.22077C11.5554 5.24753 11.575 5.27917 11.5876 5.31371C11.6001 5.34826 11.6054 5.38497 11.6033 5.42167C11.6005 5.47018 11.6112 5.51858 11.6336 5.56169C11.656 5.60481 11.6897 5.64105 11.731 5.66668C11.7723 5.69231 11.8193 5.70637 11.8679 5.70733C11.9165 5.70829 11.9647 5.69613 12.007 5.67216L12.2364 5.5414C12.3534 5.47494 12.4909 5.45534 12.6217 5.48661C12.7525 5.51788 12.8667 5.59767 12.941 5.70983C12.9973 5.79483 13.0232 5.8964 13.0146 5.99799C13.0059 6.09959 12.9632 6.19526 12.8934 6.26952L12.2288 6.97105C12.2036 6.99772 12.1727 7.01893 12.139 7.03341C12.1053 7.0479 12.0691 7.05534 12.0324 7.05526L10.7942 7.04532Z"
                                    fill="#234a5d"
                                  />
                                </svg>
                              </span>
                              <div
                                id="foodsafety_color"
                                onMouseLeave={() => this.onMouseLeave()}
                                onMouseEnter={() => this.setHoverEffect(1)}
                                className="quarter_inner"
                                style={{
                                  backgroundColor: this.calculateColor(
                                    "foodSafety",
                                    productData.foodSafety
                                  ),
                                  opacity: this.state.foodOp,
                                  transform: `scale(${foodSafetyScale})`,
                                  transformOrigin: "top left",
                                }}
                              ></div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div
                      ref={(section) => {
                        this.nutriDiv = section;
                      }}
                      className="product_dec_last_scrolled_right main-chart-element__"
                      id="main-chart-element"
                    >
                      <PerfectScrollbar
                        containerRef={(ref) => {
                          this.firstRef = ref;
                        }}
                      >
                        {this.state.defaultActive === true ? (
                          <div className="First Text d-none d-lg-flex justify-content-center align-items-center">
                            {window.matchMedia("(max-width: 992px)").matches ? (
                              <h4 className="first_content px-1">
                                Tap the chart to learn why it got a GreenScore®
                                rating of {Math.round(productData?.totalGs)}
                                /100!
                              </h4>
                            ) : (
                              <h4 className="first_content">
                                Click the chart to learn why it got a
                                GreenScore® rating of{" "}
                                {Math.round(productData?.totalGs)}/100!
                              </h4>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.nutritionActive === true ? (
                          <div
                            className={
                              this.state.nutritionActive === true
                                ? "food__court displayed"
                                : "food_court"
                            }
                          >
                            <Accordion allowZeroExpanded>
                              <div className="accordion__item">
                                <div className="accordionItemHeading_top">
                                  <h2 id="chart_1">Nutrition</h2>
                                </div>
                                <div className="AccordionItemHeading_bottom">
                                  {productData !== null ? (
                                    <h3
                                      id="chart_holder_main"
                                      // ref={(section)=>this.firstRef = section}
                                      style={{
                                        color: this.calculateColor(
                                          "nutrition",
                                          productData.nutrition
                                        ),
                                      }}
                                    >
                                      {this.calculateFactor(
                                        "nutrition",
                                        productData.nutrition
                                      )}
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        showNutritionModal: true,
                                        showBlur: true,
                                      });
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 23 23"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.0373 15.4498C11.0289 15.4901 11.0247 15.5384 11.0247 15.5947C11.0247 15.788 11.0961 15.8846 11.2389 15.8846C11.3146 15.8846 11.3986 15.8604 11.491 15.8121C11.5835 15.7638 11.7011 15.6833 11.844 15.5706L12.0078 15.945C11.865 16.1302 11.6507 16.3154 11.365 16.5005C11.0877 16.6857 10.7473 16.7783 10.344 16.7783C10.0162 16.7783 9.75995 16.7179 9.57507 16.5972C9.3902 16.4683 9.29776 16.2952 9.29776 16.0778L9.31037 15.9571C9.36079 15.5384 9.53306 14.3226 9.82717 12.3097L10.0289 10.9088L9.28516 10.5706L9.37339 10.0996L11.5415 9.80972L11.844 9.94257L11.0373 15.4498ZM11.1759 8.6503C10.949 8.6503 10.7515 8.56979 10.5835 8.40875C10.4238 8.23967 10.344 8.04644 10.344 7.82905C10.344 7.53114 10.4448 7.28154 10.6465 7.08025C10.8566 6.87896 11.1213 6.77832 11.4406 6.77832C11.7011 6.77832 11.907 6.85884 12.0583 7.01987C12.2095 7.17285 12.2852 7.35803 12.2852 7.57542C12.2852 7.88138 12.1885 8.13903 11.9952 8.34837C11.802 8.54966 11.5289 8.6503 11.1759 8.6503Z"
                                        fill="#6B9080"
                                      />
                                      <circle
                                        cx="11.2852"
                                        cy="11.7783"
                                        r="10.5"
                                        stroke="#6B9080"
                                      />
                                    </svg>
                                    <span
                                    >
                                      {" "}
                                      What is Daily Value (DV)?
                                    </span>
                                  </a>
                                </div>
                                <div className="AccordionItemHeading_last">
                                  <svg
                                    width="18"
                                    height="16"
                                    viewBox="0 0 14 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.1507 6.63976C13.1658 6.33682 13.1961 5.26141 13.181 5.18567C13.0598 4.65554 12.5145 4.27687 11.9844 4.27687C8.34918 4.27687 8.40976 4.27687 8.34918 4.27687C8.40976 4.01938 8.44006 3.76188 8.4855 3.53468C8.62182 2.88337 8.77328 2.23207 8.90961 1.58076C8.97019 1.23238 8.86416 0.899156 8.62182 0.641662C8.44006 0.459901 7.97051 0.172114 7.94022 0.202407C6.69819 1.45958 5.4713 2.70161 4.22927 3.94364C4.06266 4.11026 3.89605 4.27687 3.85061 4.51922C3.83546 4.6101 3.82031 4.70098 3.82031 4.79186C3.82031 6.79122 3.82031 8.79059 3.82031 10.8051C3.82031 11.3807 4.27471 11.8805 4.83514 11.9562C4.86543 11.9562 4.89573 11.9714 4.92602 11.9714C6.74363 11.9714 8.57638 11.9714 10.394 11.9714C10.4697 11.9563 10.5454 11.9411 10.6212 11.9108C10.9847 11.8048 11.2271 11.5624 11.3785 11.2141C11.7572 10.3204 12.1207 9.42675 12.4994 8.53309C12.6963 8.06355 12.9083 7.594 13.0901 7.1093C13.1204 6.97298 13.1507 6.80637 13.1507 6.63976Z"
                                      fill="#616161"
                                    />
                                    <path
                                      d="M3.19779 4.88379C3.1372 4.88379 3.10691 4.88379 3.06147 4.88379C2.56163 4.88379 1.41048 4.88379 0.925782 4.88379C0.683435 4.88379 0.425941 4.88379 0.183594 4.88379C0.183594 7.21638 0.183594 9.21575 0.183594 11.5483C0.971222 11.5483 2.42531 11.5483 3.21293 11.5483C3.19779 9.23089 3.19779 7.23153 3.19779 4.88379Z"
                                      fill="#616161"
                                    />
                                  </svg>
                                  To encourage
                                </div>
                              </div>
                              {productData !== null && encourage_nutrient
                                ? Object.keys(encourage_nutrient).map(
                                  (nutrientKey, index) => {
                                    const nutrient =
                                      encourage_nutrient[nutrientKey];
                                    const infoAlert =
                                      nutrient &&
                                      nutrient?.infoAlert;
                                    if (
                                      nutrient.value ||
                                      nutrient.value === null
                                    ) {
                                      const nutrientValue = Math.ceil(
                                        nutrient.value
                                      );

                                      const trianleColor =
                                        this.getEncourageNutrientColor(
                                          nutrientValue
                                        );

                                      // if(nutrition.gsSystem.includes('nutri')){
                                      //   const trianleColor =
                                      // 5 <= 10 ? '#93d02f' : '#3a7729'
                                      // var dv = nutrition.description.substring(nutrition.description.indexOf('('),nutrition.description.indexOf('%'));
                                      // console.log('DV',dv)
                                      // }
                                      if (
                                        nutrientValue &&
                                        nutrientValue !== null &&
                                        nutrientValue !== 0
                                      )
                                        return (
                                          <AccordionItem
                                            className=""
                                            key={`accordian_item_${index}`}
                                          >
                                            <div className="accordionItemHeading_top"></div>
                                            <AccordionItemHeading
                                            >
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  <span className="tabs-dot">
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          trianleColor,
                                                      }}
                                                      className="dot-inner"
                                                    ></span>
                                                  </span>
                                                </div>
                                                {getFormattedHeading(
                                                  nutrientKey
                                                )}{" "}
                                                <span
                                                  className={
                                                    encourage_nutrient[
                                                      nutrientKey
                                                    ].description
                                                      ? "successColor"
                                                      : "falseColor"
                                                  }
                                                >
                                                  {encourage_nutrient[
                                                    nutrientKey
                                                  ].description
                                                    ? encourage_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : "No data available"}
                                                </span>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            {nutrientValue &&
                                              nutrientValue !== null &&
                                              nutrientValue !== 0 ? (
                                              <AccordionItemPanel>
                                                <div className="range-slider">
                                                  <div className="range-color green">
                                                    <div
                                                      className="range-tooltip"
                                                      style={{
                                                        left: `${this.getTooltipValue(
                                                          nutrientValue
                                                        )}%`,
                                                      }}
                                                    >
                                                      <div className="range-per">
                                                        {nutrientValue}%
                                                      </div>
                                                      <div className="range-text">
                                                        of total DV
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="triangle"
                                                    style={{
                                                      borderBottom: `solid 6px ${trianleColor}`,
                                                      left: `calc(${this.getTriangleValue(
                                                        nutrientValue
                                                      )}% - 8px)`,
                                                    }}
                                                  ></div>
                                                </div>
                                                <div className="range-width-container">
                                                  <div>0%</div>
                                                  <div>5%</div>
                                                  <div>10%</div>
                                                  <div>15%</div>
                                                  <div>20%</div>
                                                  <div>40%</div>
                                                  <div>100%</div>
                                                </div>
                                                <div className="information">
                                                  {encourage_nutrient[
                                                    nutrientKey
                                                  ].description
                                                    ? encourage_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : "No data available"}
                                                </div>
                                                <div className="info">
                                                  {infoAlert &&
                                                    infoAlert.info && (
                                                      <p>
                                                        <svg
                                                          width="5"
                                                          height="17"
                                                          viewBox="0 0 5 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M2.72397 14.4329C2.72397 14.5875 2.74974 14.7099 2.80129 14.8001C2.86572 14.8775 2.94304 14.9161 3.03325 14.9161C3.175 14.9161 3.31676 14.8775 3.45851 14.8001C3.61315 14.7099 3.80001 14.5617 4.01908 14.3555L4.27037 14.8581C4.07707 15.1287 3.78068 15.4123 3.38119 15.7086C2.9817 16.005 2.51134 16.1532 1.97009 16.1532C1.51906 16.1532 1.17112 16.0501 0.926268 15.844C0.694307 15.6378 0.597656 15.3349 0.636317 14.9354C0.70075 14.3684 0.868278 13.1506 1.1389 11.2821C1.49973 8.78203 1.7188 7.21629 1.79612 6.58484L0.597656 6.12092L0.732967 5.44437L3.59382 4.99978L4.03841 5.21241L2.7433 14.2396L2.72397 14.4329ZM3.26521 2.95079C2.96881 2.95079 2.71108 2.84125 2.49201 2.62217C2.28582 2.4031 2.18272 2.13892 2.18272 1.82964C2.18272 1.43015 2.31803 1.08865 2.58866 0.805145C2.87216 0.521637 3.20078 0.379883 3.57449 0.379883C3.92243 0.379883 4.19305 0.48942 4.38635 0.708495C4.57966 0.914682 4.67631 1.17242 4.67631 1.4817C4.66342 1.89407 4.52811 2.24202 4.27037 2.52552C4.01264 2.80903 3.67758 2.95079 3.26521 2.95079Z"
                                                            fill="#4D99BA"
                                                          />
                                                        </svg>{" "}
                                                        {infoAlert.info}
                                                      </p>
                                                    )}
                                                </div>
                                              </AccordionItemPanel>
                                            ) : (
                                              ""
                                            )}
                                          </AccordionItem>
                                        );
                                      else {
                                        return (
                                          <AccordionItem
                                            className="hid"
                                            dangerouslySetExpanded={false}
                                            key={`accordian_item_${index}`}
                                          >
                                            <div className="accordionItemHeading_top"></div>
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  <span className="tabs-dot">
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          trianleColor,
                                                      }}
                                                      className="dot-inner"
                                                    ></span>
                                                  </span>
                                                </div>
                                                {getFormattedHeading(
                                                  nutrientKey
                                                )}{" "}
                                                <span
                                                  className={
                                                    encourage_nutrient[
                                                      nutrientKey
                                                    ].description
                                                      ? "successColor"
                                                      : "falseColor"
                                                  }
                                                >
                                                  {encourage_nutrient[
                                                    nutrientKey
                                                  ].description
                                                    ? encourage_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : "No data available"}
                                                </span>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                          </AccordionItem>
                                        );
                                      }
                                    }
                                  }
                                )
                                : ""}
                              {plant_based &&
                                Object.keys(plant_based).length > 0 ? (
                                <AccordionItem
                                  className="hid main-item"
                                  dangerouslySetExpanded={true}
                                >
                                  <div className="accordionItemHeading_top"></div>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="icon">
                                        <svg
                                          width="14"
                                          height="9"
                                          viewBox="0 0 14 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.97656 5.02609L4.42347 8.00053L12.6614 1.80469"
                                            stroke="#0CAD07"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                      {plant_based.description} <span>{ }</span>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div className="info">
                                      <p>{plant_based.infoText}</p>
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              ) : (
                                ""
                              )}
                              <div className="AccordionItemHeading_last">
                                <svg
                                  transform="rotate(180)"
                                  width="18"
                                  height="16"
                                  viewBox="0 0 14 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.1507 6.63976C13.1658 6.33682 13.1961 5.26141 13.181 5.18567C13.0598 4.65554 12.5145 4.27687 11.9844 4.27687C8.34918 4.27687 8.40976 4.27687 8.34918 4.27687C8.40976 4.01938 8.44006 3.76188 8.4855 3.53468C8.62182 2.88337 8.77328 2.23207 8.90961 1.58076C8.97019 1.23238 8.86416 0.899156 8.62182 0.641662C8.44006 0.459901 7.97051 0.172114 7.94022 0.202407C6.69819 1.45958 5.4713 2.70161 4.22927 3.94364C4.06266 4.11026 3.89605 4.27687 3.85061 4.51922C3.83546 4.6101 3.82031 4.70098 3.82031 4.79186C3.82031 6.79122 3.82031 8.79059 3.82031 10.8051C3.82031 11.3807 4.27471 11.8805 4.83514 11.9562C4.86543 11.9562 4.89573 11.9714 4.92602 11.9714C6.74363 11.9714 8.57638 11.9714 10.394 11.9714C10.4697 11.9563 10.5454 11.9411 10.6212 11.9108C10.9847 11.8048 11.2271 11.5624 11.3785 11.2141C11.7572 10.3204 12.1207 9.42675 12.4994 8.53309C12.6963 8.06355 12.9083 7.594 13.0901 7.1093C13.1204 6.97298 13.1507 6.80637 13.1507 6.63976Z"
                                    fill="#616161"
                                  />
                                  <path
                                    d="M3.19779 4.88379C3.1372 4.88379 3.10691 4.88379 3.06147 4.88379C2.56163 4.88379 1.41048 4.88379 0.925782 4.88379C0.683435 4.88379 0.425941 4.88379 0.183594 4.88379C0.183594 7.21638 0.183594 9.21575 0.183594 11.5483C0.971222 11.5483 2.42531 11.5483 3.21293 11.5483C3.19779 9.23089 3.19779 7.23153 3.19779 4.88379Z"
                                    fill="#616161"
                                  />
                                </svg>
                                To Limit
                              </div>
                              {productData !== null && limit_nutrient
                                ? Object.keys(limit_nutrient).map(
                                  (nutrientKey, index) => {
                                    const nutrient =
                                      limit_nutrient[nutrientKey];
                                    const infoAlert =
                                      nutrient &&
                                      nutrient?.infoAlert;
                                    const nutrientValue = Math.ceil(
                                      nutrient.value
                                    );
                                    const trianleColor =
                                      this.getLimitNutrientColor(
                                        nutrientValue
                                      );
                                    // console.log('Nutri color',trianleColor)
                                    if (
                                      nutrient.value ||
                                      nutrient.value === null
                                    ) {
                                      if (
                                        nutrientValue &&
                                        nutrientValue !== null &&
                                        nutrientValue !== 0
                                      )
                                        return (
                                          <AccordionItem
                                            className=""
                                            key={`accordian_item_2_${index}_desk`}
                                          >
                                            <div className="accordionItemHeading_top"></div>
                                            <AccordionItemHeading
                                            >
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  <span className="tabs-dot">
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          trianleColor,
                                                      }}
                                                      className="dot-inner"
                                                    ></span>
                                                  </span>
                                                </div>
                                                {getFormattedHeading(
                                                  nutrientKey
                                                )}{" "}
                                                <span
                                                  className={
                                                    limit_nutrient[
                                                      nutrientKey
                                                    ].description
                                                      ? "successColor"
                                                      : "falseColor"
                                                  }
                                                >
                                                  {limit_nutrient[nutrientKey]
                                                    .description
                                                    ? limit_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : ""}
                                                </span>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            {nutrientValue &&
                                              nutrientValue !== null ? (
                                              <AccordionItemPanel>
                                                <div className="range-slider">
                                                  <div className="range-color">
                                                    <div
                                                      className="range-tooltip"
                                                      style={{
                                                        left: `${this.getTooltipValue(
                                                          nutrientValue
                                                        )}%`,
                                                      }}
                                                    >
                                                      <div className="range-per">
                                                        {nutrientValue}%
                                                      </div>
                                                      <div className="range-text">
                                                        of total DV
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="triangle"
                                                    style={{
                                                      borderBottom: `solid 6px ${trianleColor} !important`,
                                                      left: `calc(${this.getTriangleValue(
                                                        nutrientValue
                                                      )}% - 8px)`,
                                                    }}
                                                  ></div>
                                                </div>
                                                <div className="range-width-container">
                                                  <div>0%</div>
                                                  <div>5%</div>
                                                  <div>10%</div>
                                                  <div>15%</div>
                                                  <div>20%</div>
                                                  <div>40%</div>
                                                  <div>100%</div>
                                                </div>
                                                <div className="information">
                                                  {limit_nutrient[nutrientKey]
                                                    .description
                                                    ? limit_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : "No data available"}
                                                </div>
                                                <div className="info">
                                                  {infoAlert &&
                                                    infoAlert.info && (
                                                      <p>
                                                        <svg
                                                          width="5"
                                                          height="17"
                                                          viewBox="0 0 5 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M2.72397 14.4329C2.72397 14.5875 2.74974 14.7099 2.80129 14.8001C2.86572 14.8775 2.94304 14.9161 3.03325 14.9161C3.175 14.9161 3.31676 14.8775 3.45851 14.8001C3.61315 14.7099 3.80001 14.5617 4.01908 14.3555L4.27037 14.8581C4.07707 15.1287 3.78068 15.4123 3.38119 15.7086C2.9817 16.005 2.51134 16.1532 1.97009 16.1532C1.51906 16.1532 1.17112 16.0501 0.926268 15.844C0.694307 15.6378 0.597656 15.3349 0.636317 14.9354C0.70075 14.3684 0.868278 13.1506 1.1389 11.2821C1.49973 8.78203 1.7188 7.21629 1.79612 6.58484L0.597656 6.12092L0.732967 5.44437L3.59382 4.99978L4.03841 5.21241L2.7433 14.2396L2.72397 14.4329ZM3.26521 2.95079C2.96881 2.95079 2.71108 2.84125 2.49201 2.62217C2.28582 2.4031 2.18272 2.13892 2.18272 1.82964C2.18272 1.43015 2.31803 1.08865 2.58866 0.805145C2.87216 0.521637 3.20078 0.379883 3.57449 0.379883C3.92243 0.379883 4.19305 0.48942 4.38635 0.708495C4.57966 0.914682 4.67631 1.17242 4.67631 1.4817C4.66342 1.89407 4.52811 2.24202 4.27037 2.52552C4.01264 2.80903 3.67758 2.95079 3.26521 2.95079Z"
                                                            fill="#4D99BA"
                                                          />
                                                        </svg>{" "}
                                                        {infoAlert.info}
                                                      </p>
                                                    )}
                                                </div>
                                              </AccordionItemPanel>
                                            ) : (
                                              ""
                                            )}
                                          </AccordionItem>
                                        );
                                      else {
                                        return (
                                          <AccordionItem
                                            dangerouslySetExpanded={false}
                                            className="hid n-border"
                                            key={`accordian_item_2_${index}_desk`}
                                          >
                                            <div className="accordionItemHeading_top"></div>
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  <span className="tabs-dot">
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          trianleColor,
                                                      }}
                                                      className="dot-inner"
                                                    ></span>
                                                  </span>
                                                </div>
                                                {getFormattedHeading(
                                                  nutrientKey
                                                )}{" "}
                                                <span
                                                  className={
                                                    limit_nutrient[
                                                      nutrientKey
                                                    ].description
                                                      ? "successColor"
                                                      : "falseColor"
                                                  }
                                                >
                                                  {limit_nutrient[nutrientKey]
                                                    .description
                                                    ? limit_nutrient[
                                                      nutrientKey
                                                    ].description
                                                    : "No data available"}
                                                </span>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            {nutrientValue &&
                                              nutrientValue !== null ? (
                                              <AccordionItemPanel>
                                                <div className="range-slider">
                                                  <div className="range-color">
                                                    <div
                                                      className="range-tooltip"
                                                      style={{
                                                        left: `${this.getTooltipValue(
                                                          nutrientValue
                                                        )}%`,
                                                      }}
                                                    >
                                                      <div className="range-per">
                                                        {nutrientValue}%
                                                      </div>
                                                      <div className="range-text">
                                                        of total DV
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="triangle"
                                                    style={{
                                                      borderBottom: `solid 6px ${trianleColor}`,
                                                      left: `calc(${this.getTriangleValue(
                                                        nutrientValue
                                                      )}% - 8px)`,
                                                    }}
                                                  ></div>
                                                </div>
                                                <div className="range-width-container">
                                                  <div>0%</div>
                                                  <div>5%</div>
                                                  <div>10%</div>
                                                  <div>15%</div>
                                                  <div>20%</div>
                                                  <div>40%</div>
                                                  <div>100%</div>
                                                </div>
                                              </AccordionItemPanel>
                                            ) : (
                                              ""
                                            )}
                                          </AccordionItem>
                                        );
                                      }
                                    } else {
                                      return (
                                        <AccordionItem
                                          dangerouslySetExpanded={false}
                                          className="hid no-border"
                                          key={`accordian_item_2_${index}_desk`}
                                        >
                                          <div className="accordionItemHeading_top"></div>
                                          <AccordionItemHeading>
                                            <AccordionItemButton>
                                              <div className="icon">
                                                <svg
                                                  width="14"
                                                  height="9"
                                                  viewBox="0 0 14 9"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M1.97656 5.02609L4.42347 8.00053L12.6614 1.80469"
                                                    stroke="#0CAD07"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                              {"No " +
                                                getFormattedHeading(
                                                  nutrientKey
                                                )}
                                            </AccordionItemButton>
                                          </AccordionItemHeading>
                                        </AccordionItem>
                                      );
                                    }
                                  }
                                )
                                : ""}
                              {added_sugar &&
                                Object.keys(added_sugar).length > 0 ? (
                                <AccordionItem
                                  className="hid main-item"
                                  dangerouslySetExpanded={true}
                                >
                                  <div className="accordionItemHeading_top"></div>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="icon">
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          color={"red"}
                                        />
                                      </div>
                                      {added_sugar.description} <span>{ }</span>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div className="info">
                                      <p>{added_sugar.infoText}</p>
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              ) : (
                                ""
                              )}
                            </Accordion>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.foodSafetyActive === true ? (
                          <div
                            className={
                              this.state.foodSafetyActive
                                ? "food__court displayed food-safety"
                                : "food__court"
                            }
                          >
                            <Accordion allowZeroExpanded>
                              <div className="accordion__item">
                                <div
                                  id="chart_2"
                                  className="accordionItemHeading_top"
                                >
                                  <h2>Food safety</h2>
                                </div>
                                <div className="AccordionItemHeading_bottom mb-5">
                                  {productData !== null ? (
                                    <h3
                                      style={{
                                        color: this.calculateColor(
                                          "foodSafety",
                                          productData.foodSafety
                                        ),
                                      }}
                                    >
                                      {this.calculateFactor(
                                        "foodSafety",
                                        productData.foodSafety
                                      )}
                                    </h3>
                                  ) : (
                                    ""
                                  )}{" "}
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        showFoodModal: true,
                                        showBlur: true,
                                      });
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 23 23"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.0373 15.4498C11.0289 15.4901 11.0247 15.5384 11.0247 15.5947C11.0247 15.788 11.0961 15.8846 11.2389 15.8846C11.3146 15.8846 11.3986 15.8604 11.491 15.8121C11.5835 15.7638 11.7011 15.6833 11.844 15.5706L12.0078 15.945C11.865 16.1302 11.6507 16.3154 11.365 16.5005C11.0877 16.6857 10.7473 16.7783 10.344 16.7783C10.0162 16.7783 9.75995 16.7179 9.57507 16.5972C9.3902 16.4683 9.29776 16.2952 9.29776 16.0778L9.31037 15.9571C9.36079 15.5384 9.53306 14.3226 9.82717 12.3097L10.0289 10.9088L9.28516 10.5706L9.37339 10.0996L11.5415 9.80972L11.844 9.94257L11.0373 15.4498ZM11.1759 8.6503C10.949 8.6503 10.7515 8.56979 10.5835 8.40875C10.4238 8.23967 10.344 8.04644 10.344 7.82905C10.344 7.53114 10.4448 7.28154 10.6465 7.08025C10.8566 6.87896 11.1213 6.77832 11.4406 6.77832C11.7011 6.77832 11.907 6.85884 12.0583 7.01987C12.2095 7.17285 12.2852 7.35803 12.2852 7.57542C12.2852 7.88138 12.1885 8.13903 11.9952 8.34837C11.802 8.54966 11.5289 8.6503 11.1759 8.6503Z"
                                        fill="#6B9080"
                                      />
                                      <circle
                                        cx="11.2852"
                                        cy="11.7783"
                                        r="10.5"
                                        stroke="#6B9080"
                                      />
                                    </svg>
                                    <span
                                    >
                                      What is food safety?
                                    </span>
                                  </a>
                                </div>
                              </div>

                              {productData !== null &&
                                this.state.gsDescription !== undefined
                                ? this.state.gsDescription.map(
                                  (foodSafety, ind) => {
                                    if (
                                      foodSafety.gsSystem.includes("food")
                                    ) {
                                      let isInfo = false;
                                      let accDescription = "";
                                      if (
                                        foodSafety.infoAlert &&
                                        foodSafety.infoAlert !== "" &&
                                        foodSafety.infoAlert !== null
                                      ) {
                                        let val = foodSafety &&
                                          foodSafety?.infoAlert
                                          ;
                                        // console.log('INFO',val.info)
                                        if (
                                          val.info !== undefined &&
                                          Array.isArray(val.info) &&
                                          val.info.length > 0
                                        ) {
                                          isInfo = true;
                                          accDescription = val.info[0];
                                        }
                                      }
                                      if (
                                        accDescription !== "" &&
                                        accDescription !== null &&
                                        accDescription !== undefined
                                      )
                                        return (
                                          <AccordionItem
                                            className=""
                                            key={`accordian_item_3_${ind}`}
                                          >
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  {foodSafety.descriptionType ==
                                                    "positive" && (
                                                      <FontAwesomeIcon
                                                        color={"green"}
                                                        icon={faCheck}
                                                      />
                                                    )}
                                                  {foodSafety.descriptionType ==
                                                    "neutral" && (
                                                      <FontAwesomeIcon
                                                        color={"black"}
                                                        icon={faMinus}
                                                      />
                                                    )}
                                                  {foodSafety.descriptionType ==
                                                    "negative" && (
                                                      <FontAwesomeIcon
                                                        icon={faTimes}
                                                        color={"red"}
                                                      />
                                                    )}
                                                  {/* <svg
                                                        version="1.1"
                                                        id="Capa_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        width="348.333px"
                                                        height="348.334px"
                                                        viewBox="0 0 348.333 348.334"
                                                    >
                                                        <path
                                                        d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85
                                            c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563
                                            c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85
                                            l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554
                                            L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z"
                                                        />
                                                    </svg> */}
                                                </div>
                                                {foodSafety.description}
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <div className="accordian_panel">
                                                <p>
                                                  {accDescription !== "" &&
                                                    accDescription !== null &&
                                                    accDescription !==
                                                    undefined ? (
                                                    <svg
                                                      width="5"
                                                      height="17"
                                                      viewBox="0 0 5 17"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.72397 14.4329C2.72397 14.5875 2.74974 14.7099 2.80129 14.8001C2.86572 14.8775 2.94304 14.9161 3.03325 14.9161C3.175 14.9161 3.31676 14.8775 3.45851 14.8001C3.61315 14.7099 3.80001 14.5617 4.01908 14.3555L4.27037 14.8581C4.07707 15.1287 3.78068 15.4123 3.38119 15.7086C2.9817 16.005 2.51134 16.1532 1.97009 16.1532C1.51906 16.1532 1.17112 16.0501 0.926268 15.844C0.694307 15.6378 0.597656 15.3349 0.636317 14.9354C0.70075 14.3684 0.868278 13.1506 1.1389 11.2821C1.49973 8.78203 1.7188 7.21629 1.79612 6.58484L0.597656 6.12092L0.732967 5.44437L3.59382 4.99978L4.03841 5.21241L2.7433 14.2396L2.72397 14.4329ZM3.26521 2.95079C2.96881 2.95079 2.71108 2.84125 2.49201 2.62217C2.28582 2.4031 2.18272 2.13892 2.18272 1.82964C2.18272 1.43015 2.31803 1.08865 2.58866 0.805145C2.87216 0.521637 3.20078 0.379883 3.57449 0.379883C3.92243 0.379883 4.19305 0.48942 4.38635 0.708495C4.57966 0.914682 4.67631 1.17242 4.67631 1.4817C4.66342 1.89407 4.52811 2.24202 4.27037 2.52552C4.01264 2.80903 3.67758 2.95079 3.26521 2.95079Z"
                                                        fill="#4D99BA"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {accDescription !== ""
                                                    ? accDescription
                                                    : ""}
                                                </p>
                                              </div>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        );
                                      else {
                                        return (
                                          <AccordionItem
                                            className="hid"
                                            key={`accordian_item_3_${ind}`}
                                          >
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  {foodSafety.descriptionType ===
                                                    "positive" ? (
                                                    <FontAwesomeIcon
                                                      color={"green"}
                                                      icon={faCheck}
                                                    />
                                                  ) : foodSafety.descriptionType ==
                                                    "neutral" ? (
                                                    <FontAwesomeIcon
                                                      color={"black"}
                                                      icon={faMinus}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faTimes}
                                                      color={"red"}
                                                    />
                                                  )}
                                                </div>
                                                {foodSafety.description}
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                          </AccordionItem>
                                        );
                                      }
                                    }
                                  }
                                )
                                : ""}
                            </Accordion>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* processing */}

                        {this.state.processingActive === true ? (
                          <div
                            id="processing-element"
                            className={
                              this.state.processingActive === true
                                ? "food__court displayed processing"
                                : "food__court"
                            }
                          >
                            <Accordion allowZeroExpanded id="chart_3">
                              <div className="accordion__item">
                                <div className="accordionItemHeading_top">
                                  {" "}
                                  <h2>Processing</h2>
                                </div>
                                <div className="AccordionItemHeading_bottom  mb-5">
                                  {productData !== null ? (
                                    <h3
                                      style={{
                                        color: this.calculateColor(
                                          "processing",
                                          productData.processing
                                        ),
                                      }}
                                    >
                                      {this.calculateFactor(
                                        "processing",
                                        productData.processing
                                      )}
                                    </h3>
                                  ) : (
                                    ""
                                  )}{" "}
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        showProcessModal: true,
                                        showBlur: true,
                                      });
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 23 23"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.0373 15.4498C11.0289 15.4901 11.0247 15.5384 11.0247 15.5947C11.0247 15.788 11.0961 15.8846 11.2389 15.8846C11.3146 15.8846 11.3986 15.8604 11.491 15.8121C11.5835 15.7638 11.7011 15.6833 11.844 15.5706L12.0078 15.945C11.865 16.1302 11.6507 16.3154 11.365 16.5005C11.0877 16.6857 10.7473 16.7783 10.344 16.7783C10.0162 16.7783 9.75995 16.7179 9.57507 16.5972C9.3902 16.4683 9.29776 16.2952 9.29776 16.0778L9.31037 15.9571C9.36079 15.5384 9.53306 14.3226 9.82717 12.3097L10.0289 10.9088L9.28516 10.5706L9.37339 10.0996L11.5415 9.80972L11.844 9.94257L11.0373 15.4498ZM11.1759 8.6503C10.949 8.6503 10.7515 8.56979 10.5835 8.40875C10.4238 8.23967 10.344 8.04644 10.344 7.82905C10.344 7.53114 10.4448 7.28154 10.6465 7.08025C10.8566 6.87896 11.1213 6.77832 11.4406 6.77832C11.7011 6.77832 11.907 6.85884 12.0583 7.01987C12.2095 7.17285 12.2852 7.35803 12.2852 7.57542C12.2852 7.88138 12.1885 8.13903 11.9952 8.34837C11.802 8.54966 11.5289 8.6503 11.1759 8.6503Z"
                                        fill="#6B9080"
                                      />
                                      <circle
                                        cx="11.2852"
                                        cy="11.7783"
                                        r="10.5"
                                        stroke="#6B9080"
                                      />
                                    </svg>
                                    <span
                                    >
                                      {" "}
                                      What is processing?
                                    </span>
                                  </a>
                                </div>
                              </div>

                              {productData !== null &&
                                this.state.gsDescription !== undefined
                                ? this.state.gsDescription.map(
                                  (processing, ind) => {
                                    if (
                                      processing.gsSystem.includes("process")
                                    ) {
                                      let isInfo = false;
                                      let accDescription = "";
                                      if (
                                        processing.infoAlert &&
                                        processing.infoAlert !== "" &&
                                        processing.infoAlert !== null
                                      ) {
                                        let val = processing &&
                                          processing?.infoAlert
                                          ;
                                        // console.log('INFO',val.info)
                                        if (
                                          val.info !== undefined &&
                                          Array.isArray(val.info) &&
                                          val.info.length > 0
                                        ) {
                                          isInfo = true;
                                          accDescription = val.info[0];
                                        }
                                      }
                                      if (
                                        accDescription &&
                                        accDescription !== ""
                                      )
                                        return (
                                          <AccordionItem
                                            className=""
                                            key={`accordian_item_4_${ind}`}
                                          >
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <div className="icon">
                                                  {processing.descriptionType ===
                                                    "positive" ? (
                                                    <FontAwesomeIcon
                                                      color={"green"}
                                                      icon={faCheck}
                                                    />
                                                  ) : processing.descriptionType ==
                                                    "neutral" ? (
                                                    <FontAwesomeIcon
                                                      color={"black"}
                                                      icon={faMinus}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faTimes}
                                                      color={"red"}
                                                    />
                                                  )}
                                                </div>
                                                {processing.description}
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <div className="accordian_panel">
                                                <p>
                                                  {accDescription !== "" ? (
                                                    <svg
                                                      width="5"
                                                      height="17"
                                                      viewBox="0 0 5 17"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.72397 14.4329C2.72397 14.5875 2.74974 14.7099 2.80129 14.8001C2.86572 14.8775 2.94304 14.9161 3.03325 14.9161C3.175 14.9161 3.31676 14.8775 3.45851 14.8001C3.61315 14.7099 3.80001 14.5617 4.01908 14.3555L4.27037 14.8581C4.07707 15.1287 3.78068 15.4123 3.38119 15.7086C2.9817 16.005 2.51134 16.1532 1.97009 16.1532C1.51906 16.1532 1.17112 16.0501 0.926268 15.844C0.694307 15.6378 0.597656 15.3349 0.636317 14.9354C0.70075 14.3684 0.868278 13.1506 1.1389 11.2821C1.49973 8.78203 1.7188 7.21629 1.79612 6.58484L0.597656 6.12092L0.732967 5.44437L3.59382 4.99978L4.03841 5.21241L2.7433 14.2396L2.72397 14.4329ZM3.26521 2.95079C2.96881 2.95079 2.71108 2.84125 2.49201 2.62217C2.28582 2.4031 2.18272 2.13892 2.18272 1.82964C2.18272 1.43015 2.31803 1.08865 2.58866 0.805145C2.87216 0.521637 3.20078 0.379883 3.57449 0.379883C3.92243 0.379883 4.19305 0.48942 4.38635 0.708495C4.57966 0.914682 4.67631 1.17242 4.67631 1.4817C4.66342 1.89407 4.52811 2.24202 4.27037 2.52552C4.01264 2.80903 3.67758 2.95079 3.26521 2.95079Z"
                                                        fill="#4D99BA"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {accDescription !== ""
                                                    ? accDescription
                                                    : ""}
                                                </p>
                                              </div>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        );
                                      else {
                                        return (
                                          <AccordionItem
                                            className="hid"
                                            key={`accordian_item_4_${ind}`}
                                          >
                                            {processing.descriptionType !==
                                              "info" ? (
                                              <AccordionItemHeading>
                                                <AccordionItemButton>
                                                  <div className="icon">
                                                    {processing.descriptionType ===
                                                      "positive" ? (
                                                      <FontAwesomeIcon
                                                        color={"green"}
                                                        icon={faCheck}
                                                      />
                                                    ) : processing.descriptionType ==
                                                      "neutral" ? (
                                                      <FontAwesomeIcon
                                                        color={"black"}
                                                        icon={faMinus}
                                                      />
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        color={"red"}
                                                        icon={faTimes}
                                                      />
                                                    )}
                                                  </div>
                                                  {processing.description}
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                            ) : (
                                              ""
                                            )}
                                          </AccordionItem>
                                        );
                                      }
                                    }
                                  }
                                )
                                : ""}
                            </Accordion>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* processing ends */}

                        {this.state.envActive === true ? (
                          <React.Fragment>
                            <div
                              ref={(section) => {
                                this.environmentRef = section;
                              }}
                              className={
                                this.state.envActive === true
                                  ? "food__court Environment__court__ac displayed"
                                  : "food__court Environment__court__ac"
                              }
                            >
                              <Accordion allowZeroExpanded>
                                <div className="accordion__item">
                                  <div className="accordionItemHeading_top">
                                    <h2>Environment</h2>
                                  </div>
                                  <div
                                    className="AccordionItemHeading_bottom  mb-5"
                                    id="chart_4"
                                  >
                                    {productData !== null ? (
                                      <h3
                                        style={{
                                          color: this.calculateColor(
                                            "environment",
                                            productData?.environment
                                          ),
                                        }}
                                      >
                                        {this.calculateFactor(
                                          "environment",
                                          productData?.environment
                                        )}
                                      </h3>
                                    ) : (
                                      ""
                                    )}
                                    <a
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          showEnvModal: true,
                                          showBlur: true,
                                        });
                                      }}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.0373 15.4498C11.0289 15.4901 11.0247 15.5384 11.0247 15.5947C11.0247 15.788 11.0961 15.8846 11.2389 15.8846C11.3146 15.8846 11.3986 15.8604 11.491 15.8121C11.5835 15.7638 11.7011 15.6833 11.844 15.5706L12.0078 15.945C11.865 16.1302 11.6507 16.3154 11.365 16.5005C11.0877 16.6857 10.7473 16.7783 10.344 16.7783C10.0162 16.7783 9.75995 16.7179 9.57507 16.5972C9.3902 16.4683 9.29776 16.2952 9.29776 16.0778L9.31037 15.9571C9.36079 15.5384 9.53306 14.3226 9.82717 12.3097L10.0289 10.9088L9.28516 10.5706L9.37339 10.0996L11.5415 9.80972L11.844 9.94257L11.0373 15.4498ZM11.1759 8.6503C10.949 8.6503 10.7515 8.56979 10.5835 8.40875C10.4238 8.23967 10.344 8.04644 10.344 7.82905C10.344 7.53114 10.4448 7.28154 10.6465 7.08025C10.8566 6.87896 11.1213 6.77832 11.4406 6.77832C11.7011 6.77832 11.907 6.85884 12.0583 7.01987C12.2095 7.17285 12.2852 7.35803 12.2852 7.57542C12.2852 7.88138 12.1885 8.13903 11.9952 8.34837C11.802 8.54966 11.5289 8.6503 11.1759 8.6503Z"
                                          fill="#6B9080"
                                        />
                                        <circle
                                          cx="11.2852"
                                          cy="11.7783"
                                          r="10.5"
                                          stroke="#6B9080"
                                        />
                                      </svg>
                                      <span
                                      >
                                        What is environmental footprint?
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </Accordion>
                            </div>

                            <div
                              className={
                                this.state.envActive === true
                                  ? "Environment__court food__court displayed"
                                  : "Environment__court food__court"
                              }
                            >
                              <div className="accordian_panel">
                                {productData !== null &&
                                  this.state.gsDescription &&
                                  this.state.gsDescription.length > 0
                                  ? this.state.gsDescription.map((env, ind) => {
                                    if (
                                      env.gsSystem &&
                                      env.gsSystem.includes("enviro")
                                    ) {
                                      let isInfo = false;
                                      let description = "";
                                      let otherDescriptions = [];
                                      let infoAlert = env
                                        ? env?.infoAlert
                                        : null;
                                      if (
                                        infoAlert !== null &&
                                        infoAlert !== undefined &&
                                        Object.keys(infoAlert).length > 0
                                      ) {
                                        if (infoAlert.info) {
                                          otherDescriptions =
                                            infoAlert.info[0].split(",");
                                          // console.log('Other descriptions',otherDescriptions)
                                        }
                                      }
                                      if (env.descriptionType === "info") {
                                        isInfo = true;
                                      }
                                      let envImage = "";
                                      let svgImage = "";
                                      let descr =
                                        env.description.toLowerCase();
                                      if (descr.includes("carbon")) {
                                        svgImage = carImage;
                                        if (
                                          descr.includes("very") &&
                                          descr.includes("low") &&
                                          descr.includes("carbon")
                                        ) {
                                          envImage = veryLowEnv;
                                        } else if (
                                          descr.includes("very") &&
                                          descr.includes("high")
                                        ) {
                                          envImage = extremeEnv;
                                        } else if (
                                          descr.includes("extreme") &&
                                          descr.includes("high")
                                        ) {
                                          envImage = extremeEnv;
                                        } else if (
                                          descr.includes("very") &&
                                          descr.includes("low")
                                        ) {
                                          envImage = veryLowEnv;
                                        } else if (descr.includes("low")) {
                                          envImage = lowEnv;
                                        } else if (
                                          descr.includes("moderate")
                                        ) {
                                          envImage = moderteEnv;
                                        } else {
                                          envImage = highEnv;
                                        }
                                      } else {
                                        svgImage = showerImage;
                                        if (
                                          descr.includes("very") &&
                                          descr.includes("low")
                                        ) {
                                          envImage = veryLowEnvWater;
                                        } else if (
                                          descr.includes("very") &&
                                          descr.includes("high")
                                        ) {
                                          envImage = extremeEnvWater;
                                        } else if (
                                          descr.includes("extreme") &&
                                          descr.includes("high")
                                        ) {
                                          envImage = extremeEnvWater;
                                        } else if (descr.includes("low")) {
                                          envImage = lowEnvWater;
                                        } else if (
                                          descr.includes("moderate")
                                        ) {
                                          envImage = moderteEnvWater;
                                        } else {
                                          envImage = highEnvWater;
                                        }
                                      }
                                      return (
                                        <div
                                          className="foot__print"
                                          key={`env_${ind}`}
                                        >
                                          <div className="foot__print_left">
                                            <div className="foot__print_dec_svg">
                                              {isInfo === false &&
                                                otherDescriptions.length > 1 ? (
                                                <img src={envImage} />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="foot__print_dec">
                                              <h3>{env.description}</h3>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                                  : ""}
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </PerfectScrollbar>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <div>
                <h2>No data found</h2>
              </div>
            )
          ) : (
            <div className="loader">
              <Spin size="small" tip="Researching..." />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Chart;
