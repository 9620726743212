import { useEffect, useState } from "react";
import { Progress } from "antd";
import { getRadialColor } from "../../Utils/CommonFunctions";
import { useSearchParams } from 'react-router-dom'
import './index.scss'
import { handleProductCardClick } from "../../Utils/Gtag";

const ProductCard = ({ product }) => {
  const [searchParams] = useSearchParams();
  const [productName, setProductName] = useState("")
  const [gs, setGs] = useState(0)
  const [productUrl, setProductUrl] = useState("")
  const [img, setImg] = useState("")

  const client = searchParams.get("client")
  const temp = searchParams.get("temp")

  useEffect(() => {
    if (!client) {
      setProductName(product?.productName)
      setGs(product?.totalGs)
      setProductUrl(`https://qrdemo.greenchoice.co/p/${product?.productName.split(" ").join("-")}`)
      if (product?.productImage.includes("https://greenchoice.gumlet.com/")) {
        setImg("./images/placeholder.png")
      } else {
        setImg(product?.productImage)
      }
    } else {
      switch (client) {
        case "mfc":
          setProductName(product?.inv_name)
          setGs(product?.totalGs)
          setProductUrl(`https://marquette.storebyweb.com/s/1000-2036/i/INV-1000-${product?.inv_pk}`)
          setImg(`https://marquette.storebyweb.com/api/img/1000-${product?.image_fk}`)
          break;

        case "orcasprovisions":
          setProductName(product["Retailer Product Name"]);
          setGs(product?.totalGs)
          temp ? setProductUrl(`https://provisions-mainmarket.myshopify.com/collections/unfi/products/${product["Retailer Primary Key"]}`) : setProductUrl(`https://sanjuanislands.provisions.coop/collections/unfi/products/${product["Retailer Primary Key"]}`)
          if (product?.mainProductImage.includes("https://greenchoice.gumlet.com/")) {
            setImg("./images/placeholder.png")
          } else {
            setImg(product?.mainProductImage)
          }
          break;

        case "tpssprovisions":
          setProductName(product["Retailer Product Name"]);
          setGs(product?.totalGs)
          setProductUrl(`https://tpss.provisions.coop/collections/unfi/products/${product["Retailer Primary Key"]}`)
          if (product?.mainProductImage.includes("https://greenchoice.gumlet.com/")) {
            setImg("./images/placeholder.png")
          } else {
            setImg(product?.mainProductImage)
          }
          break;

        case "southphillyprovisions":
          setProductName(product["Retailer Product Name"]);
          setGs(product?.totalGs)
          setProductUrl(`https://southphilly.provisions.coop/collections/unfi/products/${product["Retailer Primary Key"]}`)
          if (product?.mainProductImage.includes("https://greenchoice.gumlet.com/")) {
            setImg("./images/placeholder.png")
          } else {
            setImg(product?.mainProductImage)
          }
          break;

        default:
          setProductName("")
          setGs(0)
          setProductUrl("")
          setImg("")
      }
    }
  }, [])

  return (
    <a target="_PARENT" href={productUrl}>
      <div
        className="product-card card"
        onClick={() => handleProductCardClick(productName)}
      >
        <Progress
          type="circle"
          percent={Math.round(gs)}
          size={36}
          className="fw-bold text-primary pt-1"
          strokeWidth="10"
          strokeLinecap=""
          format={(percent) => percent + ""}
          strokeColor={`${getRadialColor(Math.round(gs))}`}
        />
        <img loading="lazy" className="product-img" src={img} alt={productName} />
        <p className="product-title">{productName}</p>
      </div>
    </a>
  );
};

export default ProductCard;
