import { createBrowserRouter } from "react-router-dom";
import Layout from "../Pages/Layout";
import GSchart from "../Pages/GSChart/gschart";
import Radial from "../Pages/GSRadial";
import BFYCarousel from "../Pages/BFYcarousel";
import ProductDemo from "../Pages/ProductDemo";
import Dashboard from "../Pages/DemoDashboard";
import GSchartNative from "../Pages/GSChartNative";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/getChart",
        element: <GSchart />
      },
      {
        path: "/getRadial",
        element: <Radial />
      },
      {
        path: "/getBFY",
        element: <BFYCarousel />
      },
      {
        path: "/demo/product",
        element: <ProductDemo />
      },
      {
        path: "/demo/dashboard",
        element: <Dashboard />
      },
      {
        path: "/getChartforNativeApp",
        element: <GSchartNative />
      }
    ],
  },
]);
