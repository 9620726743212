import React from 'react'
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../../Components/ScrolltoTop'
import './index.scss';

const Layout = () => {

  return (
    <>
           <main>
              <ScrollToTop/>
              <Outlet />
           </main>
    </>
  )
}

export default Layout