import axios from "axios";

export const HTTPService = async ({
  url,
  method,
  baseURL = "https://gc-api-v2.greenchoicenow.com/api",
  data = {},
  headers = null,
  params = {}, 
}) => {
  try {
    let response = await axios({
      url: baseURL + url,
      method,
      data: data,
      headers: headers,
      params: params, 
    });
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
